<template>
  <div class="content-wrapper">
    <div class="content-body">
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">시스템 환경설정</div>
            </div>
            <div class="header-right">
              <div class="lookup-lookup" style="margin: 3px">
                <erp-button
                    button-div="GET"
                    :is-shortcut-button="true"
                    :ignore="isPopupOpened"
                    @click.native="onInitClicked"
                >
                  조회
                </erp-button>
              </div>
              <ul class="header-button">
                <li class="save keyColor">
                  <erp-button
                      button-div="SAVE"
                      :is-key-color="true"
                      :is-shortcut-button="true"
                      :ignore="isPopupOpened"
                      v-on:click.native="onSaveClicked"
                  >
                    저장
                  </erp-button>
                </li>
                <li class="reset">
                  <erp-button
                      button-div="GET"
                      :is-icon-custom="true"
                      v-on:click.native="onInitClicked"
                  >
                    초기화
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <article class="body-article">
              <!-- 아코디언 : accordion / 닫힘 : close -->
              <section
                  :class="[
                  'article-section',
                  'section-0101',
                  'accordion',
                  { close: !isSectionNo1Visible },
                ]"
              >
                <div class="section-header">
                  <div class="header-left">
                    <div
                        class="header-title"
                        @click="isSectionNo1Visible = !isSectionNo1Visible"
                    >
                      기본설정
                    </div>
                  </div>
                  <div class="header-right"></div>
                </div>
                <div class="section-body">
                  <article class="body-article">
                    <section class="article-section">
                      <div class="section-header">
                        <div class="header-left">
                          <div class="header-title">공통항목</div>
                        </div>
                      </div>
                      <div class="section-body" style="border-top-color: #000">
                        <ul class="lookupDetail-condition systemES">
                          <li class="field">
                            <div class="title">회원제/퍼블릭</div>
                            <ul class="content">
                              <li class="item" style="width: 126px">
                                <ejs-dropdownlist
                                    ref="courseClassDropdown"
                                    name="courseClassDropdown"
                                    id="courseClassDropdown"
                                    v-model="systemConfigInfo.courseClass"
                                    :dataSource="courseClassCodes"
                                    :allowFiltering="false"
                                    :fields="fields"
                                    cssClass="lookupDetail-condition-dropdown"
                                ></ejs-dropdownlist>
                              </li>
                            </ul>
                          </li>
                          <li class="field">
                            <div class="title">
                              <span
                                  class="systemESlink"
                                  v-on:click="onShowConfigCalenderPopup"
                              >캘린더 휴일 설정</span
                              >
                            </div>
                          </li>
                          <li class="field">
                            <div class="title">
                              <span
                                class="systemESlink"
                                @click="onShowRoomCalenderConfPopup"
                              >
                                객실 캘린더 휴일 설정
                              </span>
                            </div>
                          </li>
                          <li class="field">
                            <div class="title">객실료 상품코드 설정</div>
                            <ul class="content">
                              <li class="item" style="width: 126px">
                                <ejs-dropdownlist
                                  ref="roomAmtProductCodeDropdown"
                                  name="roomAmtProductCodeDropdown"
                                  id="roomAmtProductCodeDropdown"
                                  v-model="systemConfigInfo.roomAmtProductCode"
                                  :dataSource="roomAmtProductCodes"
                                  :allowFiltering="false"
                                  :fields="fields"
                                  cssClass="lookupDetail-condition-dropdown"
                                ></ejs-dropdownlist>
                              </li>
                            </ul>
                          </li>
                          <li class="field">
                            <div class="title">
                              <span
                                  class="systemESlink"
                                  v-on:click="onShowConfigCardSupplierPopup"
                              >카드사 매입처 설정</span
                              >
                            </div>
                          </li>
                          <li class="field">
                            <div class="title">
                              <span
                                  class="systemESlink"
                                  v-on:click="onShowConfigCourseHoleInfoPopup"
                              >코스 홀정보 설정</span
                              >
                            </div>
                          </li>
                          <li class="field">
                            <div class="title">
                              <span
                                  class="systemESlink"
                                  v-on:click="onShowConfigPaymentLinePopup"
                              >결재선 설정</span
                              >
                            </div>
                          </li>
                          <li class="field">
                            <div class="title">
                              <span
                                  class="systemESlink"
                                  v-on:click="onShowConfigAccountInfoPopup"
                              >계좌 정보 설정</span
                              >
                            </div>
                          </li>
                          <li class="field">
                            <div class="title">회원번호 자동 생성</div>
                            <ul class="content">
                              <li class="item switch">
                                <div class="systemESswitch">
                                  <ejs-switch
                                      v-model="systemConfigInfo.memberNoAuto"
                                      :checked="systemConfigInfo.memberNoAuto"
                                  />
                                </div>
                              </li>
                            </ul>
                          </li>
                          <li class="field">
                            <div class="title">예약 동반자 자동 등록</div>
                            <ul class="content">
                              <li class="item switch">
                                <div class="systemESswitch">
                                  <ejs-switch
                                      v-model="systemConfigInfo.resveCmpnAuto"
                                      :checked="systemConfigInfo.resveCmpnAuto"
                                  />
                                </div>
                              </li>
                            </ul>
                          </li>
                          <li class="field">
                            <div class="title">예약시 비밀번호 확인</div>
                            <ul class="content">
                              <li class="item switch">
                                <div class="systemESswitch">
                                  <ejs-switch
                                      v-model="systemConfigInfo.resvePwdConfirm"
                                      :checked="systemConfigInfo.resvePwdConfirm"
                                  />
                                </div>
                              </li>
                              <li class="item time">
                                <input-time
                                    :disabled="!systemConfigInfo.resvePwdConfirm"
                                    v-model="systemConfigInfo.startTime"
                                    :format="'HH:mm'"
                                />
                              </li>
                              <li class="item dash">~</li>
                              <li class="item time">
                                <input-time
                                    :disabled="!systemConfigInfo.resvePwdConfirm"
                                    v-model="systemConfigInfo.endTime"
                                    :format="'HH:mm'"
                                />
                              </li>
                            </ul>
                          </li>
                          <li class="field">
                            <div class="title">계산서 내장객명 표시</div>
                            <ul class="content">
                              <li class="item switch">
                                <div class="systemESswitch">
                                  <ejs-switch
                                      v-model="systemConfigInfo.billVisitDsp"
                                      :checked="systemConfigInfo.billVisitDsp"
                                  />
                                </div>
                              </li>
                            </ul>
                          </li>
                          <li class="field">
                            <div class="title">내장객 락카키 자동 생성</div>
                            <ul class="content">
                              <li class="item switch">
                                <div class="systemESswitch">
                                  <ejs-switch
                                      v-model="systemConfigInfo.visitLockerAuto"
                                      :checked="systemConfigInfo.visitLockerAuto"
                                  />
                                </div>
                              </li>
                            </ul>
                          </li>
                          <li class="field">
                            <div class="title">가변 락카 사용 여부</div>
                            <ul class="content">
                              <li class="item switch">
                                <div class="systemESswitch">
                                  <ejs-switch
                                      v-model="systemConfigInfo.variableLockerUseFlag"
                                      :checked="systemConfigInfo.variableLockerUseFlag"
                                  />
                                </div>
                              </li>
                            </ul>
                          </li>
                          <li class="field">
                            <div class="title">락카 반납 사용 여부</div>
                            <ul class="content">
                              <li class="item switch">
                                <div class="systemESswitch">
                                  <ejs-switch
                                    v-model="systemConfigInfo.lockerReturnFlag"
                                    :checked="systemConfigInfo.lockerReturnFlag"
                                  />
                                </div>
                              </li>
                            </ul>
                          </li>
                          <li class="field">
                            <div class="title">락카존 선택 가능 여부</div>
                            <ul class="content">
                              <li class="item text">
                                <span
                                  class="systemESlink"
                                  @click="onConfigTsConfCom2ByMemberDivPopupShow(10)"
                                >
                                  락카존 선택 가능 회원
                                </span>
                              </li>
                            </ul>
                          </li>
                          <li class="field">
                            <div class="title">
                              <span
                                  class="systemESlink"
                                  v-on:click="onShowConfigPenaltyPopup"
                              >골프 위약 관리</span
                              >
                            </div>
                            <ul class="content" v-if="switchRefresh">
                              <li class="item switch">
                                <div class="systemESswitch">
                                  <ejs-switch
                                      ref="penaltyUseSwitch"
                                      v-model="systemConfigInfo.penltyUse"
                                      :checked="systemConfigInfo.penltyUse"
                                      @change="onPenaltyUseChange"
                                  />
                                </div>
                              </li>
                            </ul>
                          </li>
                          <li class="field">
                            <div class="title">
                              <span
                                  class="systemESlink"
                                  v-on:click="onShowConfigMemberGradePopup"
                              >회원 등급제</span
                              >
                            </div>
                            <ul class="content" v-if="switchRefresh">
                              <li class="item switch">
                                <div class="systemESswitch">
                                  <ejs-switch
                                      ref="memberGradeUseSwitch"
                                      v-model="systemConfigInfo.memberGradeUse"
                                      :checked="systemConfigInfo.memberGradeUse"
                                      @change="onMemberGradeUseChange"
                                  />
                                </div>
                              </li>
                            </ul>
                          </li>
                          <li class="field">
                            <div class="title">선불결제 사용 여부</div>
                            <ul class="content">
                              <li class="item switch">
                                <div class="systemESswitch">
                                  <ejs-switch
                                    v-model="systemConfigInfo.prpayPayUseFlag"
                                    :checked="systemConfigInfo.prpayPayUseFlag"
                                  />
                                </div>
                              </li>
                              <li class="item time">
                                <input-time
                                  :disabled="!systemConfigInfo.prpayPayUseFlag"
                                  v-model="systemConfigInfo.prpayPayStartTime"
                                  :format="'HH:mm'"
                                />
                              </li>
                              <li class="item dash">~</li>
                              <li class="item time">
                                <input-time
                                  :disabled="!systemConfigInfo.prpayPayUseFlag"
                                  v-model="systemConfigInfo.prpayPayEndTime"
                                  :format="'HH:mm'"
                                />
                              </li>
                            </ul>
                          </li>
                          <li class="field">
                            <div class="title">메모 확인 여부</div>
                            <ul class="content">
                              <li class="item switch">
                                <div class="systemESswitch">
                                  <ejs-switch
                                    v-model="systemConfigInfo.memoConfirmFlag"
                                    :checked="systemConfigInfo.memoConfirmFlag"
                                  />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </section>
                  </article>
                </div>
              </section>
              <!-- 아코디언 : accordion / 닫힘 : close -->
              <section
                  :class="[
                  'article-section',
                  'section-0102',
                  'accordion',
                  { close: !isSectionNo2Visible },
                ]"
              >
                <div class="section-header">
                  <div class="header-left">
                    <div
                        class="header-title"
                        @click="isSectionNo2Visible = !isSectionNo2Visible"
                    >
                      입장료/카트료 설정
                    </div>
                  </div>
                </div>
                <div class="section-body">
                  <article class="body-article">
                    <section class="article-section">
                      <div class="section-header">
                        <div class="header-left">
                          <div class="header-title">요금 분할 기준</div>
                        </div>
                      </div>
                      <div class="section-body" style="border-top-color: #000">
                        <ul class="lookupDetail-condition systemES">
                          <li class="field">
                            <div class="title">인프라요금</div>
                            <ul class="content">
                              <li class="item" style="width: 126px">
                                <input-number
                                    v-model="systemConfigInfo.infrPaymt"
                                    :min="0"
                                />
                              </li>
                            </ul>
                          </li>
                          <li class="field">
                            <div class="title">분할단위</div>
                            <ul class="content">
                              <li class="item" style="width: 126px">
                                <input-number
                                    v-model="systemConfigInfo.paymtDivideUnit"
                                    :min="0"
                                />
                              </li>
                            </ul>
                          </li>
                          <li class="field">
                            <div class="title">분할기준</div>
                            <ul class="content">
                              <li class="item" style="width: 126px">
                                <ejs-dropdownlist
                                    ref="paymtDivisionStdCodes"
                                    v-model="systemConfigInfo.paymtDivideStd"
                                    :dataSource="paymtDivisionStdCodes"
                                    :allowFiltering="false"
                                    :fields="fields"
                                    cssClass="lookupDetail-condition-dropdown"
                                ></ejs-dropdownlist>
                              </li>
                            </ul>
                          </li>
                          <li class="field">
                            <div class="title">제세금</div>
                            <ul class="content">
                              <li class="item" style="width: 126px">
                                <input-number
                                    v-model="systemConfigInfo.myTax"
                                    :min="0"
                                />
                              </li>
                              <li class="item text">※ 회원제 골프장만 입력</li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </section>
                    <section class="article-section">
                      <div class="section-header">
                        <div class="header-left">
                          <div class="header-title">골프 입장료</div>
                        </div>
                      </div>
                      <div class="section-body" style="border-top-color: #000">
                        <ul class="lookupDetail-condition systemES">
                          <li class="field">
                            <div class="title">특별소비세</div>
                            <ul class="content">
                              <li class="item" style="width: 126px">
                                <input-number
                                    v-model="systemConfigInfo.specialTax"
                                    :min="0"
                                    :disabled="isPublicCourse()"
                                />
                              </li>
                            </ul>
                          </li>
                          <li class="field">
                            <div class="title">교육세</div>
                            <ul class="content">
                              <li class="item" style="width: 126px">
                                <input-number
                                    v-model="systemConfigInfo.eduTax"
                                    :min="0"
                                    :max="
                                    systemConfigInfo.eduTaxConf === 'FIX'
                                      ? null
                                      : 100
                                  "
                                    :disabled="isPublicCourse()"
                                    :allow-dot="
                                    systemConfigInfo.eduTaxConf === 'FIX'
                                      ? false
                                      : true
                                  "
                                />
                              </li>
                              <li class="item check">
                                <ul class="check">
                                  <li>
                                    <label>
                                      <input
                                          type="radio"
                                          id="eduTaxConfFix"
                                          v-model="systemConfigInfo.eduTaxConf"
                                          :value="'FIX'"
                                          :disabled="isPublicCourse()"
                                      />
                                      <i></i>
                                      <div class="label">금액</div>
                                    </label>
                                  </li>
                                  <li>
                                    <label>
                                      <input
                                          type="radio"
                                          id="eduTaxConfPer"
                                          v-model="systemConfigInfo.eduTaxConf"
                                          :value="'PER'"
                                          :disabled="isPublicCourse()"
                                      />
                                      <i></i>
                                      <div class="label">비율</div>
                                    </label>
                                  </li>
                                </ul>
                              </li>
                              <li class="item text">
                                ※ 교육세, 농특세는 "특별소비세"의 30%
                              </li>
                            </ul>
                          </li>
                          <li class="field">
                            <div class="title">농특세</div>
                            <ul class="content">
                              <li class="item" style="width: 126px">
                                <input-number
                                    v-model="systemConfigInfo.vilTax"
                                    :min="0"
                                    :max="
                                    systemConfigInfo.vilTaxConf === 'FIX'
                                      ? null
                                      : 100
                                  "
                                    :disabled="isPublicCourse()"
                                    :allow-dot="
                                    systemConfigInfo.vilTaxConf === 'FIX'
                                      ? false
                                      : true
                                  "
                                />
                              </li>
                              <li class="item check">
                                <ul class="check">
                                  <li>
                                    <label>
                                      <input
                                          type="radio"
                                          id="vilTaxConfFix"
                                          v-model="systemConfigInfo.vilTaxConf"
                                          :value="'FIX'"
                                          :disabled="isPublicCourse()"
                                      />
                                      <i></i>
                                      <div class="label">금액</div>
                                    </label>
                                  </li>
                                  <li>
                                    <label>
                                      <input
                                          type="radio"
                                          id="vilTaxConfPer"
                                          v-model="systemConfigInfo.vilTaxConf"
                                          :value="'PER'"
                                          :disabled="isPublicCourse()"
                                      />
                                      <i></i>
                                      <div class="label">비율</div>
                                    </label>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </li>
                          <li class="field">
                            <div class="title">부가세</div>
                            <ul class="content">
                              <li class="item" style="width: 126px">
                                <input-number
                                    v-model="systemConfigInfo.vat"
                                    :min="0"
                                    :max="
                                    systemConfigInfo.vatConf === 'FIX'
                                      ? null
                                      : 100
                                  "
                                    :disabled="isPublicCourse()"
                                    :allow-dot="
                                    systemConfigInfo.vatConf === 'FIX'
                                      ? false
                                      : true
                                  "
                                />
                              </li>
                              <li class="item check">
                                <ul class="check">
                                  <li>
                                    <label>
                                      <input
                                          type="radio"
                                          id="vatConfFix"
                                          v-model="systemConfigInfo.vatConf"
                                          :value="'FIX'"
                                          :disabled="isPublicCourse()"
                                      />
                                      <i></i>
                                      <div class="label">금액</div>
                                    </label>
                                  </li>
                                  <li>
                                    <label>
                                      <input
                                          type="radio"
                                          id="vatConfPer"
                                          v-model="systemConfigInfo.vatConf"
                                          :value="'PER'"
                                          :disabled="isPublicCourse()"
                                      />
                                      <i></i>
                                      <div class="label">비율</div>
                                    </label>
                                  </li>
                                </ul>
                              </li>
                              <li class="item text">
                                ※ 입장료 총액에서 체육기금을 제외하고 10%
                              </li>
                            </ul>
                          </li>
                          <li class="field">
                            <div class="title">체육기금</div>
                            <ul class="content">
                              <li class="item">
                                <ul
                                    class="lookupDetail-condition systemES systemESinner"
                                >
                                  <li class="field">
                                    <ul class="content" style="width: 100%">
                                      <li class="item" style="width: 126px">
                                        <input-number
                                            v-model="systemConfigInfo.fund1"
                                            :min="0"
                                            :disabled="isPublicCourse()"
                                        />
                                      </li>
                                      <li class="item text">원</li>
                                      <li class="item" style="width: 126px">
                                        <input-number
                                            v-model="systemConfigInfo.fundStart1"
                                            :min="0"
                                            :disabled="isPublicCourse()"
                                        />
                                      </li>
                                      <li class="item text">원 부터</li>
                                      <li class="item" style="width: 126px">
                                        <input-number
                                            v-model="systemConfigInfo.fundEnd1"
                                            :min="0"
                                            :disabled="isPublicCourse()"
                                        />
                                      </li>
                                      <li class="item text">원 까지</li>
                                    </ul>
                                  </li>
                                  <li class="field">
                                    <ul class="content" style="width: 100%">
                                      <li class="item" style="width: 126px">
                                        <input-number
                                            v-model="systemConfigInfo.fund2"
                                            :min="0"
                                            :disabled="isPublicCourse()"
                                        />
                                      </li>
                                      <li class="item text">원</li>
                                      <li class="item" style="width: 126px">
                                        <input-number
                                            v-model="systemConfigInfo.fundStart2"
                                            :min="0"
                                            :disabled="isPublicCourse()"
                                        />
                                      </li>
                                      <li class="item text">원 부터</li>
                                      <li class="item" style="width: 126px">
                                        <input-number
                                            v-model="systemConfigInfo.fundEnd2"
                                            :min="0"
                                            :disabled="isPublicCourse()"
                                        />
                                      </li>
                                      <li class="item text">원 까지</li>
                                    </ul>
                                  </li>
                                  <li class="field">
                                    <ul class="content" style="width: 100%">
                                      <li class="item" style="width: 126px">
                                        <input-number
                                            v-model="systemConfigInfo.fund3"
                                            :min="0"
                                            :disabled="isPublicCourse()"
                                        />
                                      </li>
                                      <li class="item text">원</li>
                                      <li class="item" style="width: 126px">
                                        <input-number
                                            v-model="systemConfigInfo.fundStart3"
                                            :min="0"
                                            :disabled="isPublicCourse()"
                                        />
                                      </li>
                                      <li class="item text">원 부터</li>
                                      <li class="item" style="width: 126px">
                                        <input-number
                                            v-model="systemConfigInfo.fundEnd3"
                                            :min="0"
                                            :disabled="isPublicCourse()"
                                        />
                                      </li>
                                      <li class="item text">원 까지</li>
                                    </ul>
                                  </li>
                                  <li class="field">
                                    <ul class="content" style="width: 100%">
                                      <li class="item" style="width: 126px">
                                        <input-number
                                            v-model="systemConfigInfo.fund4"
                                            :min="0"
                                            :disabled="isPublicCourse()"
                                        />
                                      </li>
                                      <li class="item text">원</li>
                                      <li class="item" style="width: 126px">
                                        <input-number
                                            v-model="systemConfigInfo.fundStart4"
                                            :min="0"
                                            :disabled="isPublicCourse()"
                                        />
                                      </li>
                                      <li class="item text">원 부터</li>
                                      <li class="item" style="width: 126px">
                                        <input-number
                                            v-model="systemConfigInfo.fundEnd4"
                                            :min="0"
                                            :disabled="isPublicCourse()"
                                        />
                                      </li>
                                      <li class="item text">원 까지</li>
                                    </ul>
                                  </li>
                                  <li class="field">
                                    <ul class="content" style="width: 100%">
                                      <li class="item" style="width: 126px">
                                        <input-number
                                            v-model="systemConfigInfo.fund5"
                                            :min="0"
                                            :disabled="isPublicCourse()"
                                        />
                                      </li>
                                      <li class="item text">원</li>
                                      <li class="item" style="width: 126px">
                                        <input-number
                                            v-model="systemConfigInfo.fundStart5"
                                            :min="0"
                                            :disabled="isPublicCourse()"
                                        />
                                      </li>
                                      <li class="item text">원 부터</li>
                                      <li class="item" style="width: 126px">
                                        <input-number
                                            v-model="systemConfigInfo.fundEnd5"
                                            :min="0"
                                            :disabled="isPublicCourse()"
                                        />
                                      </li>
                                      <li class="item text">원 까지</li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </section>
                    <section class="article-section">
                      <div class="section-header">
                        <div class="header-left">
                          <div class="header-title">골프 카트료</div>
                        </div>
                      </div>
                      <div class="section-body" style="border-top-color: #000">
                        <ul class="lookupDetail-condition systemES">
                          <li class="field">
                            <div class="title">카트료 설정</div>
                            <ul class="content">
                              <li class="item" style="width: 126px">
                                <ejs-dropdownlist
                                    ref="cartTeamDivCodesDropdown"
                                    name="cartTeamDivCodesDropdown"
                                    id="cartTeamDivCodesDropdown"
                                    v-model="systemConfigInfo.cartTeamDiv"
                                    :dataSource="cartTeamDivCodes"
                                    :allowFiltering="false"
                                    :fields="fields"
                                    cssClass="lookupDetail-condition-dropdown"
                                ></ejs-dropdownlist>
                              </li>
                              <li class="item" style="width: 126px">
                                <ejs-dropdownlist
                                    ref="cartDateDivCodesDropdown"
                                    name="cartDateDivCodesDropdown"
                                    id="cartDateDivCodesDropdown"
                                    v-model="systemConfigInfo.cartDateDiv"
                                    :dataSource="cartDateDivCodes"
                                    :allowFiltering="false"
                                    :fields="fields"
                                    cssClass="lookupDetail-condition-dropdown"
                                ></ejs-dropdownlist>
                              </li>
                              <li class="item" style="width: 126px">
                                <ejs-dropdownlist
                                    ref="cartHoleDivCodesDropdown"
                                    name="cartHoleDivCodesDropdown"
                                    id="cartHoleDivCodesDropdown"
                                    v-model="systemConfigInfo.cartHoleDiv"
                                    :dataSource="cartHoleDivCodes"
                                    :allowFiltering="false"
                                    :fields="fields"
                                    cssClass="lookupDetail-condition-dropdown"
                                ></ejs-dropdownlist>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </section>
                    <section class="article-section">
                      <div class="section-header">
                        <div class="header-left">
                          <div class="header-title">부가세 처리</div>
                        </div>
                      </div>
                      <div class="section-body" style="border-top-color: #000">
                        <ul class="lookupDetail-condition systemES">
                          <li class="field">
                            <div class="title">부가세</div>
                            <ul class="content">
                              <li class="item" style="width: 126px">
                                <ejs-dropdownlist
                                    ref="vatCalcMethodCodesDropdown"
                                    name="vatCalcMethodCodesDropdown"
                                    id="vatCalcMethodCodesDropdown"
                                    v-model="systemConfigInfo.vatCalcMethod"
                                    :dataSource="vatCalcMethodCodes"
                                    :allowFiltering="false"
                                    :fields="fields"
                                    cssClass="lookupDetail-condition-dropdown"
                                ></ejs-dropdownlist>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </section>
                    <section class="article-section">
                      <div class="section-header">
                        <div class="header-left">
                          <div class="header-title">비회원 요금</div>
                        </div>
                      </div>
                      <div class="section-body" style="border-top-color: #000">
                        <ul class="lookupDetail-condition systemES">
                          <li class="field">
                            <div class="title">요금코드</div>
                            <ul class="content">
                              <li class="item" style="width: 186px">
                                <ejs-dropdownlist
                                    ref="paymtCodesDropdown"
                                    name="paymtCodesDropdown"
                                    id="paymtCodesDropdown"
                                    v-model="systemConfigInfo.nomemberPaymtCode"
                                    :dataSource="paymtCodes"
                                    :allowFiltering="false"
                                    :fields="fields"
                                    cssClass="lookupDetail-condition-dropdown"
                                ></ejs-dropdownlist>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </section>
                  </article>
                </div>
              </section>
              <!-- 아코디언 : accordion / 닫힘 : close -->
              <section
                  :class="[
                  'article-section',
                  'section-0103',
                  'accordion',
                  { close: !isSectionNo3Visible },
                ]"
              >
                <div class="section-header">
                  <div class="header-left">
                    <div
                        class="header-title"
                        @click="isSectionNo3Visible = !isSectionNo3Visible"
                    >
                      개인정보 보안
                    </div>
                  </div>
                  <div class="header-right"></div>
                </div>
                <div class="section-body">
                  <ul class="lookupDetail-condition systemES">
                    <li class="field">
                      <div class="title">로그인 실패 횟수 제한</div>
                      <ul class="content">
                        <li class="item switch">
                          <div class="systemESswitch">
                            <ejs-switch
                                v-model="systemConfigInfo.loginFailLmtt"
                                :checked="systemConfigInfo.loginFailLmtt"
                            />
                          </div>
                        </li>
                        <li class="item" style="width: 66px">
                          <input-number
                              :disabled="!systemConfigInfo.loginFailLmtt"
                              v-model="systemConfigInfo.loginFailCnt"
                              :min="1"
                          />
                        </li>
                        <li class="item text">회</li>
                        <li class="item text">
                          ※ 로그인 5회 실패시 프로그램 사용이 제한 됩니다.
                        </li>
                      </ul>
                    </li>
                    <li class="field">
                      <div class="title">자동 로그아웃</div>
                      <ul class="content">
                        <li class="item switch">
                          <div class="systemESswitch">
                            <ejs-switch
                                v-model="systemConfigInfo.autoLogout"
                                :checked="systemConfigInfo.autoLogout"
                            />
                          </div>
                        </li>
                        <li class="item" style="width: 66px">
                          <input-number
                              :disabled="!systemConfigInfo.autoLogout"
                              v-model="systemConfigInfo.autoLogoutTime"
                              :min="1"
                              :max="999"
                              :prop-max-length="3"
                          />
                        </li>
                        <li class="item text">분</li>
                      </ul>
                    </li>
                    <li class="field">
                      <div class="title">휴면 계정 사용</div>
                      <ul class="content">
                        <li class="item switch">
                          <div class="systemESswitch">
                            <ejs-switch
                                v-model="systemConfigInfo.drmncyAcntUse"
                                :checked="systemConfigInfo.drmncyAcntUse"
                            />
                          </div>
                        </li>
                        <li class="item" style="width: 66px">
                          <input-number
                              :disabled="!systemConfigInfo.drmncyAcntUse"
                              v-model="systemConfigInfo.drmncyAcntProcess"
                              :min="1"
                              :max="999"
                              :prop-max-length="3"
                          />
                        </li>
                        <li class="item text">년 경과 분리보관</li>
                        <li class="item text" style="margin-left: 10px;">설정 조건</li>
                        <li class="item check">
                          <ul class="check">
                            <li>
                              <label>
                                <input
                                  type="checkbox"
                                  id="homepageLoginConf"
                                  :disabled="!systemConfigInfo.drmncyAcntUse"
                                  v-model="systemConfigInfo.homepageLoginConf"
                                  value="true"
                                />
                                <i></i>
                                <div class="label">홈페이지 로그인</div>
                              </label>
                            </li>
                            <li>
                              <label>
                                <input
                                  type="checkbox"
                                  id="resveRceptConf"
                                  :disabled="!systemConfigInfo.drmncyAcntUse"
                                  v-model="systemConfigInfo.resveRceptConf"
                                  value="true"
                                />
                                <i></i>
                                <div class="label">예약 접수</div>
                              </label>
                            </li>
                            <li>
                              <label>
                                <input
                                  type="checkbox"
                                  id="cmpnInsertConf"
                                  :disabled="!systemConfigInfo.drmncyAcntUse"
                                  v-model="systemConfigInfo.cmpnInsertConf"
                                  value="true"
                                />
                                <i></i>
                                <div class="label">동반자 등록</div>
                              </label>
                            </li>
                            <li>
                              <label>
                                <input
                                  type="checkbox"
                                  id="visitInsertConf"
                                  :disabled="!systemConfigInfo.drmncyAcntUse"
                                  v-model="systemConfigInfo.visitInsertConf"
                                  value="true"
                                />
                                <i></i>
                                <div class="label">내장객 등록</div>
                              </label>
                            </li>
                            <li>
                              <label>
                                <input
                                  type="checkbox"
                                  id="depositBlceConf"
                                  :disabled="!systemConfigInfo.drmncyAcntUse"
                                  v-model="systemConfigInfo.depositBlceConf"
                                  value="true"
                                />
                                <i></i>
                                <div class="label">선수금 잔액</div>
                              </label>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li class="field">
                      <div class="title"></div>
                      <ul class="content">
                        <li class="item text">자동처리구분</li>
                        <li class="item check">
                          <ul class="check">
                            <li>
                              <label>
                                <input
                                  type="radio"
                                  value="AUTO"
                                  :disabled="true"
                                  v-model="systemConfigInfo.autoDiv"
                                  name="autoDivByAuto"
                                />
                                <i></i>
                                <div class="label">자동</div>
                              </label>
                            </li>
                            <li>
                              <label>
                                <input
                                  type="radio"
                                  value="MANUAL"
                                  :disabled="!systemConfigInfo.drmncyAcntUse"
                                  v-model="systemConfigInfo.autoDiv"
                                  name="autoDivByManual"
                                />
                                <i></i>
                                <div class="label">수동</div>
                              </label>
                            </li>
                          </ul>
                        </li>
                        <li class="item text" style="margin-left: 10px;">SMS 전송 기준일</li>
                        <li class="item" style="width: 66px">
                          <input-number
                            :disabled="!systemConfigInfo.drmncyAcntUse"
                            v-model="systemConfigInfo.smsSendStdde"
                            :allow-minus="false"
                            :propMaxLength="2"
                          />
                        </li>
                        <li class="item text">일 전</li>
                        <li class="item text" style="margin-left: 10px;">SMS 자동 전송 시간</li>
                        <li class="item" style="width: 66px">
                          <input-time
                            :disabled="
                              !systemConfigInfo.drmncyAcntUse ||
                              systemConfigInfo.autoDiv !== 'AUTO'
                            "
                            v-model="systemConfigInfo.smsSendTime"
                            :format="'HH:mm'"
                          />
                        </li>
                        <li class="item text" style="margin-left: 10px;">
                          <span
                            :class="{
                              systemESlink: systemConfigInfo.drmncyAcntUse
                            }"
                            @click="onConfigTsConfCom2ByMemberDivPopupShow(9)"
                          >
                            회원구분
                          </span>
                        </li>
                        <li
                          v-show="systemConfigInfo.confCom2MemberDiv"
                          class="item text"
                          style="height: 23px; margin: 3px 0 0; padding: 2px 3px 5px 3px; background-color: #f0f0f0; box-sizing: border-box; border: 1px solid #e0e0e0; border-radius: 3px;"
                        >
                          {{ systemConfigInfo.confCom2MemberDiv }}
                        </li>
                      </ul>
                    </li>
                    <li class="field">
                      <div class="title">비밀번호 최소/최대 자릿수</div>
                      <ul class="content">
                        <li class="item" style="width: 66px">
                          <input-number
                              v-model="systemConfigInfo.pwdMinLength"
                              :min="1"
                              :max="systemConfigInfo.pwdMaxLength"
                          />
                        </li>
                        <li class="item text">자리 /</li>
                        <li class="item" style="width: 66px">
                          <input-number
                              v-model="systemConfigInfo.pwdMaxLength"
                              :min="systemConfigInfo.pwdMinLength"
                          />
                        </li>
                        <li class="item text">자리</li>
                      </ul>
                    </li>
                    <li class="field">
                      <div class="title">비밀번호 변경 주기</div>
                      <ul class="content">
                        <li class="item" style="width: 126px">
                          <ejs-dropdownlist
                              ref="pwdChangeCycleCodesDropdown"
                              name="pwdChangeCycleCodesDropdown"
                              id="pwdChangeCycleCodesDropdown"
                              v-model="systemConfigInfo.pwdChangeCycle"
                              :dataSource="pwdChangeCycleCodes"
                              :allowFiltering="false"
                              :fields="fields"
                              cssClass="lookupDetail-condition-dropdown"
                          ></ejs-dropdownlist>
                        </li>
                      </ul>
                    </li>
                    <li class="field">
                      <div class="title">다음에 변경하기 허용</div>
                      <ul class="content">
                        <li class="item switch">
                          <div class="systemESswitch">
                            <ejs-switch
                                v-model="systemConfigInfo.pwdNextPerm"
                                :checked="systemConfigInfo.pwdNextPerm"
                            />
                          </div>
                        </li>
                      </ul>
                    </li>
                    <li class="field">
                      <div class="title">비밀번호 보안 수준</div>
                      <ul class="content">
                        <li class="item" style="width: 126px">
                          <ejs-dropdownlist
                              ref="pwdScrtyLevelCodesDropdown"
                              name="pwdScrtyLevelCodesDropdown"
                              id="pwdScrtyLevelCodesDropdown"
                              v-model="systemConfigInfo.pwdScrtyLevel"
                              :dataSource="pwdScrtyLevelCodes"
                              :allowFiltering="false"
                              :fields="fields"
                              cssClass="lookupDetail-condition-dropdown"
                          ></ejs-dropdownlist>
                        </li>
                        <li class="item text">
                          ※ 낮음<br/>
                          ㆍ 8~15자의 영문, 숫자, 특수문자만 가능.<br/>
                          ㆍ 영문, 숫자 혼용.<br/>
                          ㆍ 공백문자 사용불가.<br/>
                          ㆍ 일부 특수문자 사용가능. (! @@ # $ % ^ & * ( ) _ + |
                          { } &lt; &gt; : ; [ ] - = , . ?)<br/><br/>
                          ※ 높음<br/>
                          ㆍ 쉬움 설정의 제약 포함.<br/>
                          ㆍ 본인 Email ID 포함 불가.<br/>
                          ㆍ 3자리 이상 반복문자 사용불가.<br/>
                          ㆍ 3회 이상 연속 된 알파벳 사용 불가.<br/>
                          ㆍ 3회 이상 연속 된 숫자 사용 불가.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </section>
              <!-- 아코디언 : accordion / 닫힘 : close -->
              <section
                  :class="[
                  'article-section',
                  'section-0104',
                  'accordion',
                  { close: !isSectionNo4Visible },
                ]"
              >
                <div class="section-header">
                  <div class="header-left">
                    <div
                        class="header-title"
                        @click="isSectionNo4Visible = !isSectionNo4Visible"
                    >
                      예약 추첨
                    </div>
                  </div>
                  <div class="header-right"></div>
                </div>
                <div class="section-body">
                  <ul class="lookupDetail-condition systemES">
                    <li class="field">
                      <div class="title">대기예약 사용</div>
                      <ul class="content">
                        <li class="item switch">
                          <div class="systemESswitch">
                            <ejs-switch
                                v-model="systemConfigInfo.weekdayWaitingUse"
                                :checked="systemConfigInfo.weekdayWaitingUse"
                            />
                          </div>
                        </li>
                      </ul>
                    </li>
                    <li class="field">
                      <div class="title">추첨 사용</div>
                      <ul class="content">
                        <li class="item switch">
                          <div class="systemESswitch">
                            <ejs-switch
                                v-model="systemConfigInfo.weekendDrwtUse"
                                :checked="systemConfigInfo.weekendDrwtUse"
                            />
                          </div>
                        </li>
                      </ul>
                    </li>
                    <li class="field" v-if="systemConfigInfo.weekendDrwtUse">
                      <div class="title">추첨 위약 체크</div>
                      <ul class="content">
                        <li class="item switch">
                          <div class="systemESswitch">
                            <ejs-switch
                                :disabled="!systemConfigInfo.weekendDrwtUse"
                                v-model="systemConfigInfo.weekendDrwtPenltyCheck"
                                :checked="systemConfigInfo.weekendDrwtPenltyCheck"
                            />
                          </div>
                        </li>
                      </ul>
                    </li>
                    <li class="field" v-if="systemConfigInfo.weekendDrwtUse">
                      <div class="title">예약 체크 기간</div>
                      <ul class="content">
                        <li class="item" style="width: 126px">
                          <ejs-dropdownlist
                              :readonly="!systemConfigInfo.weekendDrwtUse"
                              v-model="systemConfigInfo.weekdayResveTerm"
                              :dataSource="resvePeriodCodes"
                              :allowFiltering="false"
                              :fields="fields"
                              cssClass="lookupDetail-condition-dropdown"
                          ></ejs-dropdownlist>
                        </li>
                        <li class="item text">1건 당</li>
                        <li class="item" style="width: 66px">
                          <input-number
                              :disabled="!systemConfigInfo.weekendDrwtUse"
                              v-model="systemConfigInfo.weekdayResveScore"
                              :max="0"
                              :allow-minus="true"
                          />
                        </li>
                        <li class="item text">점</li>
                      </ul>
                    </li>
                    <li class="field" v-if="systemConfigInfo.weekendDrwtUse">
                      <div class="title">예약 체크 기간</div>
                      <ul class="content">
                        <li class="item" style="width: 126px">
                          <ejs-dropdownlist
                              :readonly="!systemConfigInfo.weekendDrwtUse"
                              v-model="systemConfigInfo.weekendResveTerm"
                              :dataSource="resvePeriodCodes"
                              :allowFiltering="false"
                              :fields="fields"
                              cssClass="lookupDetail-condition-dropdown"
                          ></ejs-dropdownlist>
                        </li>
                        <li class="item text">1건 당</li>
                        <li class="item" style="width: 66px">
                          <input-number
                              :disabled="!systemConfigInfo.weekendDrwtUse"
                              v-model="systemConfigInfo.weekendResveScore"
                              :max="0"
                              :allow-minus="true"
                          />
                        </li>
                        <li class="item text">점</li>
                      </ul>
                    </li>
                    <li class="field" v-if="systemConfigInfo.weekendDrwtUse">
                      <div class="title">
                        <span
                            class="systemESlink"
                            v-on:click="onShowConfigWeekendDrwtPopup"
                        >추첨 우선 순위</span
                        >
                      </div>
                    </li>
                    <li class="field" v-if="systemConfigInfo.weekendDrwtUse">
                      <div class="title">예약일정</div>
                      <ul class="content">
                        <li class="item">
                          <ul
                              class="lookupDetail-condition systemES systemESinner"
                          >
                            <li class="field">
                              <div class="title">신청시작일자</div>
                              <ul class="content">
                                <li class="item" style="width: 66px">
                                  <input-number
                                      :disabled="!systemConfigInfo.weekendDrwtUse"
                                      v-model="systemConfigInfo.weekendRqstde"
                                      :min="0"
                                      :prop-max-length="3"
                                  />
                                </li>
                                <li class="item text">주전</li>
                                <li class="item" style="width: 126px">
                                  <ejs-dropdownlist
                                      :readonly="!systemConfigInfo.weekendDrwtUse"
                                      v-model="systemConfigInfo.weekendRqstdw"
                                      :dataSource="dwCodes"
                                      :allowFiltering="false"
                                      :fields="fields"
                                      cssClass="lookupDetail-condition-dropdown"
                                  ></ejs-dropdownlist>
                                </li>
                                <li class="item time">
                                  <input-time
                                      :disabled="!systemConfigInfo.weekendDrwtUse"
                                      v-model="systemConfigInfo.weekendRqsttm"
                                      :format="'HH:mm'"
                                  />
                                </li>
                              </ul>
                            </li>
                            <li class="field">
                              <div class="title">신청마감일자</div>
                              <ul class="content">
                                <li class="item" style="width: 66px">
                                  <input-number
                                      :disabled="!systemConfigInfo.weekendDrwtUse"
                                      v-model="
                                      systemConfigInfo.weekendReqstClosde
                                    "
                                      :min="0"
                                      :prop-max-length="3"
                                  />
                                </li>
                                <li class="item text">주전</li>
                                <li class="item" style="width: 126px">
                                  <ejs-dropdownlist
                                      :readonly="!systemConfigInfo.weekendDrwtUse"
                                      v-model="
                                      systemConfigInfo.weekendReqstClosdw
                                    "
                                      :dataSource="dwCodes"
                                      :allowFiltering="false"
                                      :fields="fields"
                                      cssClass="lookupDetail-condition-dropdown"
                                  ></ejs-dropdownlist>
                                </li>
                                <li class="item time">
                                  <input-time
                                      :disabled="!systemConfigInfo.weekendDrwtUse"
                                      v-model="
                                      systemConfigInfo.weekendReqstClostm
                                    "
                                      :format="'HH:mm'"
                                  />
                                </li>
                              </ul>
                            </li>
                            <li class="field">
                              <div class="title">확정통보일자</div>
                              <ul class="content">
                                <li class="item" style="width: 66px">
                                  <input-number
                                      :disabled="!systemConfigInfo.weekendDrwtUse"
                                      v-model="systemConfigInfo.weekendConfde"
                                      :min="0"
                                      :prop-max-length="3"
                                  />
                                </li>
                                <li class="item text">주전</li>
                                <li class="item" style="width: 126px">
                                  <ejs-dropdownlist
                                      :readonly="!systemConfigInfo.weekendDrwtUse"
                                      v-model="systemConfigInfo.weekendConfdw"
                                      :dataSource="dwCodes"
                                      :allowFiltering="false"
                                      :fields="fields"
                                      cssClass="lookupDetail-condition-dropdown"
                                  ></ejs-dropdownlist>
                                </li>
                                <li class="item time">
                                  <input-time
                                      :disabled="!systemConfigInfo.weekendDrwtUse"
                                      v-model="systemConfigInfo.weekendConftm"
                                      :format="'HH:mm'"
                                  />
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </section>
              <!-- 아코디언 : accordion / 닫힘 : close -->
              <section
                  :class="[
                  'article-section',
                  'section-0105',
                  'accordion',
                  { close: !isSectionNo5Visible },
                ]"
              >
                <div class="section-header">
                  <div class="header-left">
                    <div
                        class="header-title"
                        @click="isSectionNo5Visible = !isSectionNo5Visible"
                    >
                      실시간/대기 예약
                    </div>
                  </div>
                  <div class="header-right"></div>
                </div>
                <div class="section-body">
                  <ul class="lookupDetail-condition systemES">
                    <li class="field">
                      <div class="title">티오프 시간</div>
                      <ul class="content">
                        <li class="item time">
                          <input-time
                              v-model="systemConfigInfo.teeoffStartTime"
                              :format="'HH:mm'"
                          />
                        </li>
                        <li class="item dash">~</li>
                        <li class="item time">
                          <input-time
                              v-model="systemConfigInfo.teeoffEndTime"
                              :format="'HH:mm'"
                          />
                        </li>
                      </ul>
                    </li>
                    <li class="field">
                      <div class="title">티오프 간격</div>
                      <ul class="content">
                        <li class="item" style="width: 66px">
                          <input-number
                              v-model="systemConfigInfo.teeOffIntrvl1"
                              :min="0"
                              :prop-max-length="3"
                          />
                        </li>
                        <li class="item text">분</li>
                        <li class="item" style="width: 66px">
                          <input-number
                              v-model="systemConfigInfo.teeOffIntrvl2"
                              :min="0"
                              :prop-max-length="3"
                          />
                        </li>
                        <li class="item text">분</li>
                        <li class="item" style="width: 66px">
                          <input-number
                              v-model="systemConfigInfo.teeOffIntrvl3"
                              :min="0"
                              :prop-max-length="3"
                          />
                        </li>
                        <li class="item text">분</li>
                      </ul>
                    </li>
                    <li class="field">
                      <div class="title">예약 오픈 방법</div>
                      <ul class="content">
                        <li class="item" style="width: 126px">
                          <ejs-dropdownlist
                              v-model="systemConfigInfo.resveOpenMethod"
                              :dataSource="resveOpenMethodCodes"
                              :allowFiltering="false"
                              :fields="fields"
                              cssClass="lookupDetail-condition-dropdown"
                          ></ejs-dropdownlist>
                        </li>
                        <li class="item" style="width: 66px">
                          <input-number
                              v-model="systemConfigInfo.resveOpenDayCnt"
                              :min="0"
                              :prop-max-length="3"
                          />
                        </li>
                        <li class="item text">일전</li>
                        <li class="item time">
                          <input-time
                              v-model="systemConfigInfo.resveOpenTime"
                              :format="'HH:mm'"
                          />
                        </li>
                        <li class="item text">시 오픈</li>
                        <li class="item" style="width: 66px">
                          <input-number
                              v-model="systemConfigInfo.resveClosdeCnt"
                              :min="0"
                              :prop-max-length="3"
                          />
                        </li>
                        <li class="item text">일전</li>
                        <li class="item time">
                          <input-time
                              v-model="systemConfigInfo.resveClostm"
                              :format="'HH:mm'"
                          />
                        </li>
                        <li class="item text">시 마감</li>
                      </ul>
                    </li>
                    <li class="field">
                      <div class="title">취소 마감시간</div>
                      <ul class="content">
                        <li class="item">
                          <ul
                              class="lookupDetail-condition systemES systemESinner"
                          >
                            <li class="field">
                              <ul class="content" style="width: 100%">
                                <li class="item text">주중</li>
                                <li class="item" style="width: 66px">
                                  <input-number
                                      v-model="systemConfigInfo.weekdayCncde"
                                      :min="0"
                                      :prop-max-length="3"
                                  />
                                </li>
                                <li class="item text">일전</li>
                                <li class="item time">
                                  <input-time
                                      v-model="systemConfigInfo.weekdayCnctime"
                                      :format="'HH:mm'"
                                  />
                                </li>
                              </ul>
                            </li>
                            <li class="field">
                              <ul class="content" style="width: 100%">
                                <li class="item text">주말</li>
                                <li class="item" style="width: 66px">
                                  <input-number
                                      v-model="systemConfigInfo.weekendCncde"
                                      :min="0"
                                      :prop-max-length="3"
                                  />
                                </li>
                                <li class="item text">일전</li>
                                <li class="item time">
                                  <input-time
                                      v-model="systemConfigInfo.weekendCnctime"
                                      :format="'HH:mm'"
                                  />
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li class="field">
                      <div class="title">변경 마감시간</div>
                      <ul class="content">
                        <li class="item">
                          <ul
                              class="lookupDetail-condition systemES systemESinner"
                          >
                            <li class="field">
                              <ul class="content" style="width: 100%">
                                <li class="item text">주중</li>
                                <li class="item" style="width: 66px">
                                  <input-number
                                      v-model="systemConfigInfo.weekdayChgde"
                                      :min="0"
                                      :prop-max-length="3"
                                  />
                                </li>
                                <li class="item text">일전</li>
                                <li class="item time">
                                  <input-time
                                      v-model="systemConfigInfo.weekdayChgtime"
                                      :format="'HH:mm'"
                                  />
                                </li>
                              </ul>
                            </li>
                            <li class="field">
                              <ul class="content" style="width: 100%">
                                <li class="item text">주말</li>
                                <li class="item" style="width: 66px">
                                  <input-number
                                      v-model="systemConfigInfo.weekendChgde"
                                      :min="0"
                                      :prop-max-length="3"
                                  />
                                </li>
                                <li class="item text">일전</li>
                                <li class="item time">
                                  <input-time
                                      v-model="systemConfigInfo.weekendChgtime"
                                      :format="'HH:mm'"
                                  />
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li class="field">
                      <div class="title">당일 예약가능</div>
                      <ul class="content">
                        <li class="item switch">
                          <div class="systemESswitch">
                            <ejs-switch
                                v-model="systemConfigInfo.todayResveFlag"
                                :checked="systemConfigInfo.todayResveFlag"
                            />
                          </div>
                        </li>
                        <li class="item text" style="margin-left: 32px;">Tee-Off</li>
                        <li class="item" style="width: 49px">
                          <input-time
                              v-model="systemConfigInfo.todayResveOpenTime"
                              :format="'HH:mm'"
                          />
                        </li>
                        <li class="item text">이후 Tee-Off</li>
                        <li class="item" style="width: 66px">
                          <input-number
                              :disabled="!systemConfigInfo.todayResveFlag"
                              v-model="systemConfigInfo.todayResveTime"
                              :min="0"
                              :prop-max-length="3"
                          />
                        </li>
                        <li class="item text">시간 전 까지 오픈</li>
                      </ul>
                    </li>
                    <li class="field">
                      <div class="title">CAPTCHA 사용</div>
                      <ul class="content">
                        <li class="item switch">
                          <div class="systemESswitch">
                            <ejs-switch
                                v-model="systemConfigInfo.captchaUseFlag"
                                :checked="systemConfigInfo.captchaUseFlag"
                            />
                          </div>
                        </li>
                        <li class="item text" style="margin-left: 32px;">&nbsp;</li>
                        <li class="item" style="width: 60px">
                          <input-number
                              :disabled="!systemConfigInfo.captchaUseFlag"
                              v-model="systemConfigInfo.captchaCipher"
                              :min="4"
                              :max="6"
                          />
                        </li>
                        <li class="item text">자리</li>
                        <li class="item text" style="margin-left: 32px;">보안 수준</li>
                        <li class="item" style="width: 120px;">
                          <ejs-dropdownlist
                              ref="captchaScrtyLevelDropdown"
                              name="captchaScrtyLevelDropdown"
                              id="captchaScrtyLevelDropdown"
                              :disabled="!systemConfigInfo.captchaUseFlag"
                              :enabled="systemConfigInfo.captchaUseFlag"
                              v-model="systemConfigInfo.captchaScrtyLevel"
                              :dataSource="captchaScrtyLevelCodes"
                              :allowFiltering="false"
                              :fields="fields"
                              cssClass="lookupDetail-condition-dropdown"
                          />
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </section>
              <!-- 아코디언 : accordion / 닫힘 : close -->
              <section
                  :class="[
                  'article-section',
                  'section-0106',
                  'accordion',
                  { close: !isSectionNo6Visible },
                ]"
              >
                <div class="section-header">
                  <div class="header-left">
                    <div
                        class="header-title"
                        @click="isSectionNo6Visible = !isSectionNo6Visible"
                    >
                      경기진행
                    </div>
                  </div>
                  <div class="header-right"></div>
                </div>
                <div class="section-body">
                  <ul class="lookupDetail-condition systemES">
                    <li class="field">
                      <div class="title">배치표 알림메시지 출력</div>
                      <ul class="content">
                        <li class="item switch">
                          <div class="systemESswitch">
                            <ejs-switch
                                v-model="systemConfigInfo.caddieTcketNtcn"
                                :checked="systemConfigInfo.caddieTcketNtcn"
                            />
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </section>
              <!-- 아코디언 : accordion / 닫힘 : close -->
              <section
                  :class="[
                  'article-section',
                  'section-0107',
                  'accordion',
                  { close: !isSectionNo7Visible },
                ]"
              >
                <div class="section-header">
                  <div class="header-left">
                    <div
                        class="header-title"
                        @click="isSectionNo7Visible = !isSectionNo7Visible"
                    >
                      SMS/카카오톡
                    </div>
                  </div>
                  <div class="header-right"></div>
                </div>
                <div class="section-body">
                  <ul class="lookupDetail-condition systemES">
                    <li class="field">
                      <div class="title">SMS 사용</div>
                      <ul class="content">
                        <li class="item switch">
                          <div class="systemESswitch">
                            <ejs-switch
                                v-model="systemConfigInfo.smsUse"
                                :checked="systemConfigInfo.smsUse"
                            />
                          </div>
                        </li>
                      </ul>
                    </li>
                    <li class="field">
                      <div class="title">SMS VAN</div>
                      <ul class="content">
                        <li class="item" style="width: 126px">
                          <ejs-dropdownlist
                              :readonly="!systemConfigInfo.smsUse"
                              v-model="systemConfigInfo.smsVan"
                              :dataSource="smsVanCodes"
                              :allowFiltering="false"
                              :fields="fields"
                              cssClass="lookupDetail-condition-dropdown"
                          ></ejs-dropdownlist>
                        </li>
                      </ul>
                    </li>
                    <li class="field">
                      <div class="title">발신번호</div>
                      <ul class="content">
                        <li class="item" style="width: 126px">
                          <component-telephone
                            v-model="systemConfigInfo.dsptchNo"
                            :max-length="11"
                          />
                        </li>
                      </ul>
                    </li>
                    <li class="field">
                      <div class="title">사업장명/표시 위치</div>
                      <ul class="content">
                        <li class="item" style="width: 252px">
                          <input-textarea
                            v-model="systemConfigInfo.bizName"
                            :rows="3"
                            maxlength="-1"
                          />
                        </li>
                        <li class="item" style="width: 126px">
                          <ejs-dropdownlist
                              v-model="systemConfigInfo.bizNameLocation"
                              :dataSource="bizNameLocationCodes"
                              :allowFiltering="false"
                              :fields="fields"
                              cssClass="lookupDetail-condition-dropdown"
                          ></ejs-dropdownlist>
                        </li>
                      </ul>
                    </li>
                    <li class="field">
                      <div class="title">예약확인 자동 SMS</div>
                      <ul class="content">
                        <li class="item">
                          <ul
                              class="lookupDetail-condition systemES systemESinner"
                          >
                            <li class="field">
                              <ul class="content" style="width: 100%">
                                <li class="item switch">
                                  <div class="systemESswitch">
                                    <ejs-switch
                                        v-model="
                                        systemConfigInfo.resveSmsSendFlag
                                      "
                                        :checked="
                                        systemConfigInfo.resveSmsSendFlag
                                      "
                                    />
                                  </div>
                                </li>
                              </ul>
                            </li>
                            <li class="field">
                              <ul class="content" style="width: 100%">
                                <li class="item" style="width: 66px">
                                  <input-number
                                      :disabled="
                                      !systemConfigInfo.resveSmsSendFlag
                                    "
                                      v-model="systemConfigInfo.smsAutoSendDe1"
                                      :min="0"
                                      :prop-max-length="3"
                                  />
                                </li>
                                <li class="item text">일전</li>
                                <li class="item time">
                                  <input-time
                                      :disabled="
                                      !systemConfigInfo.resveSmsSendFlag
                                    "
                                      v-model="systemConfigInfo.smsAutoSendTime1"
                                      :format="'HH:mm'"
                                  />
                                </li>
                                <li class="item text">발송</li>
                              </ul>
                            </li>
                            <li class="field">
                              <ul class="content" style="width: 100%">
                                <li class="item" style="width: 66px">
                                  <input-number
                                      :disabled="
                                      !systemConfigInfo.resveSmsSendFlag
                                    "
                                      v-model="systemConfigInfo.smsAutoSendDe2"
                                      :min="0"
                                      :prop-max-length="3"
                                  />
                                </li>
                                <li class="item text">일전</li>
                                <li class="item time">
                                  <input-time
                                      :disabled="
                                      !systemConfigInfo.resveSmsSendFlag
                                    "
                                      v-model="systemConfigInfo.smsAutoSendTime2"
                                      :format="'HH:mm'"
                                  />
                                </li>
                                <li class="item text">발송</li>
                              </ul>
                            </li>
                            <li class="field">
                              <ul class="content" style="width: 100%">
                                <li class="item" style="width: 66px">
                                  <input-number
                                      :disabled="
                                      !systemConfigInfo.resveSmsSendFlag
                                    "
                                      v-model="systemConfigInfo.smsAutoSendDe3"
                                      :min="0"
                                      :prop-max-length="3"
                                  />
                                </li>
                                <li class="item text">일전</li>
                                <li class="item time">
                                  <input-time
                                      :disabled="
                                      !systemConfigInfo.resveSmsSendFlag
                                    "
                                      v-model="systemConfigInfo.smsAutoSendTime3"
                                      :format="'HH:mm'"
                                  />
                                </li>
                                <li class="item text">발송</li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li class="field">
                      <div class="title">지역민 만기 자동 SMS</div>
                      <ul class="content">
                        <li class="item">
                          <ul class="lookupDetail-condition systemES systemESinner">
                            <li class="field">
                              <ul class="content" style="width: 100%">
                                <li class="item switch">
                                  <div class="systemESswitch">
                                    <ejs-switch
                                      v-model="systemConfigInfo.nearbySmsSendFlag"
                                      :checked="systemConfigInfo.nearbySmsSendFlag"
                                    />
                                  </div>
                                </li>
                              </ul>
                            </li>
                            <li class="field">
                              <ul class="content" style="width: 100%">
                                <li class="item" style="width: 66px">
                                  <input-number
                                    :disabled="
                                      !systemConfigInfo.nearbySmsSendFlag
                                    "
                                    v-model="systemConfigInfo.nearbySmsAutoSendDay1"
                                    :min="0"
                                    :prop-max-length="3"
                                  />
                                </li>
                                <li class="item text">일전</li>
                                <li class="item time">
                                  <input-time
                                    :disabled="
                                      !systemConfigInfo.nearbySmsSendFlag
                                    "
                                    v-model="systemConfigInfo.nearbySmsAutoSendTime1"
                                    :format="'HH:mm'"
                                  />
                                </li>
                                <li class="item text">발송</li>
                              </ul>
                            </li>
                            <li class="field">
                              <ul class="content" style="width: 100%">
                                <li class="item" style="width: 66px">
                                  <input-number
                                    :disabled="
                                      !systemConfigInfo.nearbySmsSendFlag
                                    "
                                    v-model="systemConfigInfo.nearbySmsAutoSendDay2"
                                    :min="0"
                                    :prop-max-length="3"
                                  />
                                </li>
                                <li class="item text">일전</li>
                                <li class="item time">
                                  <input-time
                                    :disabled="
                                      !systemConfigInfo.nearbySmsSendFlag
                                    "
                                    v-model="systemConfigInfo.nearbySmsAutoSendTime2"
                                    :format="'HH:mm'"
                                  />
                                </li>
                                <li class="item text">발송</li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li class="field">
                      <div class="title">
                        <span
                            class="systemESlink"
                            v-on:click="onShowConfigSmsTextPopup"
                        >SMS 문구 설정</span
                        >
                      </div>
                    </li>
                    <li class="field">
                      <div class="title">카카오 발신 KEY</div>
                      <ul class="content">
                        <li class="item">
                          <input-text
                              v-model="systemConfigInfo.kakaoDsptchKey"
                          />
                        </li>
                      </ul>
                    </li>
                    <li class="field">
                      <div class="title">
                        <span
                            class="systemESlink"
                            v-on:click="onShowConfigSmsEndPersonPopup"
                        >SMS 전송대상</span
                        >
                      </div>
                    </li>
                  </ul>
                </div>
              </section>
              <!-- 아코디언 : accordion / 닫힘 : close -->
              <section
                :class="[
                  'article-section',
                  'section-0108',
                  'accordion',
                  { close: !isSectionNo11Visible },
                ]"
              >
                <div class="section-header">
                  <div class="header-left">
                    <div
                      class="header-title"
                      @click="isSectionNo11Visible = !isSectionNo11Visible"
                    >
                      위약 예치금 설정
                    </div>
                  </div>
                  <div class="header-right"></div>
                </div>
                <div class="section-body">
                  <ul class="lookupDetail-condition systemES">
                    <li class="field">
                      <div class="title">사용 여부</div>
                      <ul class="content">
                        <li class="item switch">
                          <div class="systemESswitch">
                            <ejs-switch
                              v-model="systemConfigInfo.penltyDpmnyUseFlag"
                              :checked="systemConfigInfo.penltyDpmnyUseFlag"
                            />
                          </div>
                        </li>
                      </ul>
                    </li>
                    <li class="field">
                      <div class="title">재가입 제한</div>
                      <ul class="content">
                        <li class="item">
                          <ul
                            class="lookupDetail-condition systemES systemESinner"
                          >
                            <li class="field">
                              <ul class="content" style="width: 100%">
                                <li class="item" style="width: 120px">
                                  <input-number
                                    :disabled="
                                      !systemConfigInfo.penltyDpmnyUseFlag
                                    "
                                    v-model="systemConfigInfo.penltyDpmnyReSscbl"
                                    :min="0"
                                    :prop-max-length="3"
                                  />
                                </li>
                                <li class="item text">개월</li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </section>
              <!-- 아코디언 : accordion / 닫힘 : close -->
              <section
                  :class="[
                  'article-section',
                  'section-0108',
                  'accordion',
                  { close: !isSectionNo13Visible },
                ]"
              >
                <div class="section-header">
                  <div class="header-left">
                    <div
                        class="header-title"
                        @click="isSectionNo13Visible = !isSectionNo13Visible"
                    >
                      홈페이지 및 키오스크 설정
                    </div>
                  </div>
                  <div class="header-right"></div>
                </div>
                <div class="section-body">
                  <ul class="lookupDetail-condition systemES">
                    <li class="field">
                      <div class="title">홈페이지 재가입 제한 일수</div>
                      <ul class="content">
                        <li class="item" style="width: 50px">
                          <input-number
                              v-model="systemConfigInfo.webMemberEntryDaycnt"
                          />
                        </li>
                      </ul>
                    </li>
                    <li class="field">
                      <div class="title">
                      <span
                          class="systemESlink"
                          v-on:click="onShowConfigYearGrpPopup"
                      >
                        홈페이지 연단체 신청 설정
                      </span>
                      </div>
                    </li>
                    <li class="field">
                      <div class="title">키오스크 인원 선택 여부</div>
                      <ul class="content">
                        <li class="item switch">
                          <div class="systemESswitch">
                            <ejs-switch
                                v-model="systemConfigInfo.kioskPerChoiseFlag"
                                :checked="systemConfigInfo.kioskPerChoiseFlag"
                            />
                          </div>
                        </li>
                      </ul>
                    </li>
                    <li class="field">
                      <div class="title">키오스크 동반자 노출 여부</div>
                      <ul class="content">
                        <li class="item switch">
                          <div class="systemESswitch">
                            <ejs-switch
                                v-model="systemConfigInfo.kioskCmpnExpsrFlag"
                                :checked="systemConfigInfo.kioskCmpnExpsrFlag"
                            />
                          </div>
                        </li>
                      </ul>
                    </li>
                    <li class="field">
                      <div class="title">미결제 내장객 삭제 여부</div>
                      <ul class="content">
                        <li class="item switch">
                          <div class="systemESswitch">
                            <ejs-switch
                                v-model="systemConfigInfo.kioskUnsetlVisitDelFlag"
                                :checked="systemConfigInfo.kioskUnsetlVisitDelFlag"
                            />
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </section>
              <!-- 아코디언 : accordion / 닫힘 : close -->
              <section
                :class="[
                  'article-section',
                  'section-0108',
                  'accordion',
                  { close: !isSectionNo12Visible },
                ]"
              >
                <div class="section-header">
                  <div class="header-left">
                    <div
                      class="header-title"
                      @click="isSectionNo12Visible = !isSectionNo12Visible"
                    >
                      무인 체크인 설정
                    </div>
                  </div>
                  <div class="header-right"></div>
                </div>
                <div class="section-body">
                  <ul class="lookupDetail-condition systemES">
                    <li class="field">
                      <div class="title">사용 여부</div>
                      <ul class="content">
                        <li class="item switch">
                          <div class="systemESswitch">
                            <ejs-switch
                              v-model="systemConfigInfo.noneChkinUseFlag"
                              :checked="systemConfigInfo.noneChkinUseFlag"
                            />
                          </div>
                        </li>
                      </ul>
                    </li>
                    <li class="field">
                      <div class="title">시스템명</div>
                      <ul class="content">
                        <li class="item" style="width: 160px">
                          <input-text
                            :disabled="!systemConfigInfo.noneChkinUseFlag"
                            v-model="systemConfigInfo.noneChkinName"
                            maxlength="100"
                          />
                        </li>
                      </ul>
                    </li>
                    <li class="field">
                      <div class="title">위도</div>
                      <ul class="content">
                        <li class="item" style="width: 160px">
                          <input-number
                            :disabled="
                              !systemConfigInfo.noneChkinUseFlag
                            "
                            v-model="systemConfigInfo.noneChkinLa"
                            :prop-max-length="16"
                            :propMaxUnderDotLength="14"
                            :allowDot="true"
                            :allowMinus="true"
                            :allowMaxLengthMinusDotLength="true"
                          />
                        </li>
                      </ul>
                    </li>
                    <li class="field">
                      <div class="title">경도</div>
                      <ul class="content">
                        <li class="item" style="width: 160px">
                          <input-number
                            :disabled="
                              !systemConfigInfo.noneChkinUseFlag
                            "
                            v-model="systemConfigInfo.noneChkinLo"
                            :prop-max-length="17"
                            :propMaxUnderDotLength="14"
                            :allowDot="true"
                            :allowMinus="true"
                            :allowMaxLengthMinusDotLength="true"
                          />
                        </li>
                      </ul>
                    </li>
                    <li class="field">
                      <div class="title">거리(m)</div>
                      <ul class="content">
                        <li class="item" style="width: 160px">
                          <input-number
                            :disabled="
                              !systemConfigInfo.noneChkinUseFlag
                            "
                            v-model="systemConfigInfo.noneChkinDstnc"
                            :min="0"
                            :prop-max-length="4"
                          />
                        </li>
                      </ul>
                    </li>
                    <li class="field">
                      <div class="title">SMS 문구</div>
                      <ul class="content">
                        <li class="item">
                          <input-textarea
                            ref="noneChkinSmsWords"
                            :disabled="!systemConfigInfo.noneChkinUseFlag"
                            v-model="systemConfigInfo.noneChkinSmsWords"
                            :rows="8"
                            maxlength="-1"
                          />
                        </li>
                      </ul>
                    </li>
                    <li class="field">
                      <div class="title">내장객 SMS 문구</div>
                      <ul class="content">
                        <li class="item">
                          <input-textarea
                            ref="noneChkinSmsWords"
                            :disabled="!systemConfigInfo.noneChkinUseFlag"
                            v-model="systemConfigInfo.noneChkinSmsVisitWords"
                            :rows="8"
                            maxlength="-1"
                          />
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </section>
              <!-- 아코디언 : accordion / 닫힘 : close -->
              <section
                  :class="[
                  'article-section',
                  'section-0108',
                  'accordion',
                  { close: !isSectionNo8Visible },
                ]"
              >
                <div class="section-header">
                  <div class="header-left">
                    <div
                        class="header-title"
                        @click="isSectionNo8Visible = !isSectionNo8Visible"
                    >
                      우측 사이드바 설정
                    </div>
                  </div>
                  <div class="header-right"></div>
                </div>
                <div class="section-body">
                  <ul class="lookupDetail-condition systemES">
                    <li class="field">
                      <div class="title">기상 정보 사용 여부</div>
                      <ul class="content">
                        <li class="item switch">
                          <div class="systemESswitch">
                            <ejs-switch
                                v-model="systemConfigInfo.weatherInfoUseFlag"
                                :checked="systemConfigInfo.weatherInfoUseFlag"
                            />
                          </div>
                        </li>
                      </ul>
                    </li>
                    <li class="field">
                      <div class="title">기상 지역 코드</div>
                      <ul class="content">
                        <li class="item">
                          <ul
                              class="lookupDetail-condition systemES systemESinner"
                          >
                            <li class="field">
                              <ul class="content" style="width: 100%">
                                <li class="item" style="width: 120px">
                                  <input-text
                                      v-model="systemConfigInfo.weatherAreaCode.landFcst"
                                  />
                                </li>
                                <li class="item text">LAND_FCST</li>
                              </ul>
                            </li>
                            <li class="field">
                              <ul class="content" style="width: 100%">
                                <li class="item" style="width: 120px">
                                  <input-text
                                      v-model="systemConfigInfo.weatherAreaCode.midLandFcst"
                                  />
                                </li>
                                <li class="item text">MID_LAND_FCST</li>
                              </ul>
                            </li>
                            <li class="field">
                              <ul class="content" style="width: 100%">
                                <li class="item" style="width: 120px">
                                  <input-text
                                      v-model="systemConfigInfo.weatherAreaCode.midTa"
                                  />
                                </li>
                                <li class="item text">MID_TA</li>
                              </ul>
                            </li>
                            <li class="field">
                              <ul class="content" style="width: 100%">
                                <li class="item" style="width: 120px">
                                  <input-text
                                      v-model="systemConfigInfo.weatherAreaCode.nx"
                                  />
                                </li>
                                <li class="item text">단기예보 X</li>
                              </ul>
                            </li>
                            <li class="field">
                              <ul class="content" style="width: 100%">
                                <li class="item" style="width: 120px">
                                  <input-text
                                      v-model="systemConfigInfo.weatherAreaCode.ny"
                                  />
                                </li>
                                <li class="item text">단기예보 Y</li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li class="field">
                      <div class="title">기상 지역명</div>
                      <ul class="content">
                        <li class="item">
                          <input-text
                              v-model="systemConfigInfo.weatherAreaName"
                              maxlength="100"
                          />
                        </li>
                      </ul>
                    </li>
                    <li class="field">
                      <div class="title">기상 정보 URL</div>
                      <ul class="content">
                        <li class="item">
                          <input-text
                              v-model="systemConfigInfo.weatherInfoUrl"
                              maxlength="500"
                          />
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </section>
              <!-- 아코디언 : accordion / 닫힘 : close -->
              <section
                  :class="[
                  'article-section',
                  'section-0108',
                  'accordion',
                  { close: !isSectionNo9Visible },
                ]"
              >
                <div class="section-header">
                  <div class="header-left">
                    <div
                        class="header-title"
                        @click="isSectionNo9Visible = !isSectionNo9Visible"
                    >
                      마감관리
                    </div>
                  </div>
                  <div class="header-right"></div>
                </div>
                <div class="section-body">
                  <ul class="lookupDetail-condition systemES">
                    <li class="field">
                      <div class="title">
                        <span
                            class="systemESlink"
                            v-on:click="onShowConfigEndCheckListPopup"
                        >일마감 체크 항목</span
                        >
                      </div>
                    </li>
                  </ul>
                </div>
              </section>
              <section
                :class="[
                  'article-section',
                  'section-0108',
                  'accordion',
                  { close: !isSectionNo14Visible },
                ]"
              >
                <div class="section-header">
                  <div class="header-left">
                    <div
                      class="header-title"
                      @click="isSectionNo14Visible = !isSectionNo14Visible"
                    >
                      영업계획
                    </div>
                  </div>
                  <div class="header-right"></div>
                </div>
                <div class="section-body">
                  <ul class="lookupDetail-condition systemES">
                    <li class="field">
                      <div class="title">영업계획 구분</div>
                      <ul class="content">
                        <li class="item" style="width: 126px">
                          <ejs-dropdownlist
                            ref="salesPlanDiv"
                            v-model="systemConfigInfo.salesPlanDiv"
                            :dataSource="salesPlanDivCodes"
                            :allowFiltering="false"
                            :fields="fields"
                            cssClass="lookupDetail-condition-dropdown"
                          />
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </section>
              <!-- 아코디언 : accordion / 닫힘 : close -->
              <section
                  :class="[
                  'article-section',
                  'section-0108',
                  'accordion',
                  { close: !isSectionNo15Visible },
                ]"
              >
                <div class="section-header">
                  <div class="header-left">
                    <div
                        class="header-title"
                        @click="isSectionNo15Visible = !isSectionNo15Visible"
                    >
                      재고 입고금액 VAT 설정
                    </div>
                  </div>
                  <div class="header-right"></div>
                </div>
                <div class="section-body">
                  <ul class="lookupDetail-condition systemES">
                    <li class="field">
                      <div class="title">상품입고-VAT 별도</div>
                      <ul class="content">
                        <li class="item switch">
                          <div class="systemESswitch">
                            <ejs-switch
                                v-model="systemConfigInfo.productVatSpuse"
                                :checked="systemConfigInfo.productVatSpuse"
                            />
                          </div>
                        </li>
                      </ul>
                    </li>
                    <li class="field">
                      <div class="title">저장품입고-VAT 별도</div>
                      <ul class="content">
                        <li class="item switch">
                          <div class="systemESswitch">
                            <ejs-switch
                                v-model="systemConfigInfo.suppliesVatSpuse"
                                :checked="systemConfigInfo.suppliesVatSpuse"
                            />
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </section>
              <!-- 아코디언 : accordion / 닫힘 : close -->
              <section
                  :class="[
                  'article-section',
                  'section-0110',
                  'accordion',
                  { close: !isSectionNo10Visible },
                ]"
              >
                <div class="section-header">
                  <div class="header-left">
                    <div
                        class="header-title"
                        @click="isSectionNo10Visible = !isSectionNo10Visible"
                    >
                      로고 / CI / 대시보드 배경 이미지
                    </div>
                  </div>
                </div>
                <div class="section-body">
                  <article class="body-article">
                    <!-- 아코디언 : accordion / 닫힘 : close -->
                    <section
                        class="article-section section-010101"
                        style="float: left; width: calc(100% / 3)"
                    >
                      <div class="section-header">
                        <div class="header-left">
                          <div class="header-title">회사 로고</div>
                        </div>
                        <div class="header-right">
                          <ul class="header-button">
                            <li class="registration">
                              <ejs-uploader
                                  ref="logoFileUploader"
                                  name="logoFileUploader"
                                  :buttons="buttons"
                                  :allowedExtensions="
                                  configImageFileUploaderExtensions
                                "
                                  :multiple="false"
                                  maxFileSize="10000000"
                                  :selected="onConfigLogoFileSelect"
                              ></ejs-uploader>
                            </li>
                            <li class="delete">
                              <erp-button
                                  button-div="DELETE"
                                  @click.native="onDeleteConfigLogoImage"
                              >
                                삭제
                              </erp-button>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="section-body" style="border: none">
                        <div
                            class="body-photo"
                            id="ConfigLogoImagePreviewContainer"
                        >
                          <div
                              v-if="systemConfigInfo.imageLogoURL"
                              class="item"
                              id="logoPreviewImage"
                              :style="{
                              backgroundImage:
                                'url(' + systemConfigInfo.imageLogoURL + ')',
                            }"
                          ></div>
                        </div>
                      </div>
                    </section>
                    <!-- 아코디언 : accordion / 닫힘 : close -->
                    <section
                        class="article-section section-010102"
                        style="float: left; width: calc(100% / 3)"
                    >
                      <div class="section-header">
                        <div class="header-left">
                          <div class="header-title">회사 직인도장</div>
                        </div>
                        <div class="header-right">
                          <ul class="header-button">
                            <li class="registration">
                              <ejs-uploader
                                  ref="stampFileUploader"
                                  name="stampFileUploader"
                                  :buttons="buttons"
                                  :allowedExtensions="
                                  configImageFileUploaderExtensions
                                "
                                  :multiple="false"
                                  maxFileSize="10000000"
                                  :selected="onConfigStampFileSelect"
                              ></ejs-uploader>
                            </li>
                            <li class="delete">
                              <erp-button
                                  button-div="DELETE"
                                  @click.native="onDeleteConfigStampImage"
                              >
                                삭제
                              </erp-button>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="section-body" style="border: none">
                        <div
                            class="body-photo"
                            id="ConfigStampImagePreviewContainer"
                        >
                          <div
                              v-if="systemConfigInfo.imageCtpnURL"
                              class="item"
                              id="stampPreviewImage"
                              :style="{
                              backgroundImage:
                                'url(' + systemConfigInfo.imageCtpnURL + ')',
                            }"
                          ></div>
                        </div>
                      </div>
                    </section>
                    <!-- 아코디언 : accordion / 닫힘 : close -->
                    <section
                        class="article-section section-010103"
                        style="float: left; width: calc(100% / 3)"
                    >
                      <div class="section-header">
                        <div class="header-left">
                          <div class="header-title">대시보드 배경</div>
                        </div>
                        <div class="header-right">
                          <ul class="header-button">
                            <li class="registration">
                              <ejs-uploader
                                  ref="printLogoFileUploader"
                                  name="printLogoFileUploader"
                                  :buttons="buttons"
                                  :allowedExtensions="
                                  configImageFileUploaderExtensions
                                "
                                  :multiple="false"
                                  maxFileSize="10000000"
                                  :selected="onConfigPrintLogoFileSelect"
                              ></ejs-uploader>
                            </li>
                            <li class="delete">
                              <erp-button
                                  button-div="DELETE"
                                  @click.native="onDeleteConfigPrintLogoImage"
                              >
                                삭제
                              </erp-button>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="section-body" style="border: none">
                        <div
                            class="body-photo"
                            id="ConfigPrintLogoImagePreviewContainer"
                        >
                          <div
                              v-if="systemConfigInfo.imagePrintURL"
                              class="item"
                              id="printLogoPreviewImage"
                              :style="{
                              backgroundImage:
                                'url(' + systemConfigInfo.imagePrintURL + ')',
                            }"
                          ></div>
                        </div>
                      </div>
                    </section>
                    <div style="clear: both"></div>
                  </article>
                </div>
              </section>
            </article>
          </div>
        </section>
      </article>
    </div>
    <config-calender-holiday-popup
        ref="configCalenderHolidayPopup"
        v-if="showConfigCalenderHoliday"
        @popupClosed="onConfigCalenderHolidayPopupClose"
    ></config-calender-holiday-popup>
    <config-card-supplier-popup
        ref="configCardSupplierPopup"
        v-if="showConfigCardSupplier"
        @popupClosed="onConfigCardSupplierPopupClose"
    >
    </config-card-supplier-popup>
    <config-course-hole-info-popup
        ref="configCourseHoleInfoPopup"
        v-if="showConfigCourseHoleInfo"
        @popupClosed="onConfigCourseHoleInfoPopupClose"
    >
    </config-course-hole-info-popup>
    <config-payment-line-popup
        ref="configPaymentLinePopup"
        v-if="showConfigPaymentLine"
        @popupClosed="onConfigPaymentLinePopupClose"
    >
    </config-payment-line-popup>
    <config-account-info-popup
        ref="configAccountInfoPopup"
        v-if="showConfigAccountInfo"
        @popupClosed="onConfigAccountInfoPopupClose"
    >
    </config-account-info-popup>
    <config-penalty-popup
        ref="configPenaltyPopup"
        v-if="showConfigPenalty"
        @popupClosed="onConfigPenaltyPopupClose"
    >
    </config-penalty-popup>
    <config-weekend-drwt-popup
        ref="configWeekendDrwtPopup"
        v-if="showConfigWeekendDrwt"
        @popupClosed="onConfigWeekendDrwtPopupClose"
    >
    </config-weekend-drwt-popup>
    <config-sms-text-popup
        ref="configSmsTextPopup"
        v-if="showConfigSmsText"
        @popupClosed="onConfigSmsTextPopupClose"
    >
    </config-sms-text-popup>
    <config-sms-end-person-popup
        ref="configSmsEndPersonPopup"
        v-if="showConfigSmsEndPerson"
        @popupClosed="onConfigSmsEndPersonPopupClose"
    >
    </config-sms-end-person-popup>
    <config-kakao-send-key-popup
        ref="configKakaoSendKeyPopup"
        v-if="showConfigKakaoSendKey"
        @popupClosed="onConfigKakaoSendKeyPopupClose"
    >
    </config-kakao-send-key-popup>
    <config-end-check-list-popup
        ref="configEndCheckListPopup"
        v-if="showConfigEndCheckList"
        @popupClosed="onConfigEndCheckListPopupClose"
    >
    </config-end-check-list-popup>
    <config-member-grade-setting-popup
        ref="configMemberGradeSettingPopup"
        v-if="showConfigMemberGradePopup"
        @popupClosed="onConfigMemberGradePopupClose"
    />
    <room-calender-conf-popup
      ref="roomCalenderConfPopup"
      v-if="isRoomCalenderConfPopupOpen"
      @popupClosed="onRoomCalenderConfPopupOpenClose"
    />
    <config-year-grp-popup
      ref="configYearGrpPopup"
      v-if="showConfigYearGrpPopup"
      @popupClosed="onConfigYearGrpPopupClose"
    />
    <config-ts-conf-com2-by-member-div-popup
      ref="configTsConfCom2ByMemberDivPopup"
      v-if="isConfigTsConfCom2ByMemberDivPopup"
      @popupClosed="onConfigTsConfCom2ByMemberDivPopupClose"
    />
  </div>
</template>

<style scoped>
body .appContent .body-article .body-article .article-section {height: auto}
body .appContent .body-article .body-article .article-section .section-body {overflow: visible}
body .lookupDetail-condition.systemES .title {width: 158px}
body .lookupDetail-condition.systemES .title .systemESlink {text-decoration: underline;cursor: pointer}
body .lookupDetail-condition.systemES .item .systemESlink {text-decoration: underline;cursor: pointer}
body .lookupDetail-condition.systemES .content {width: calc(100% - 158px)}
body .lookupDetail-condition.systemES .content .systemESswitch {padding: 1px 0}
body .lookupDetail-condition.systemES .content .systemESswitch >>> .e-switch-wrapper {display: block;width: 40px;height: 21px}
body .lookupDetail-condition.systemES .content .systemESswitch >>> .e-switch-wrapper .e-switch-inner {border-radius: 3px}
body .lookupDetail-condition.systemES .content .systemESswitch >>> .e-switch-wrapper .e-switch-on {background-color: #2e7458;opacity: 1}
body .lookupDetail-condition.systemES .content .systemESswitch >>> .e-switch-wrapper .e-switch-off {background-color: #e0e0e0;opacity: 1}
body .lookupDetail-condition.systemES .content .systemESswitch >>> .e-switch-wrapper .e-switch-handle {box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.06);width: 17px;height: 17px;margin: 2px;border-radius: 3px;background-color: #fff}
body .lookupDetail-condition.systemES .content .systemESswitch >>> .e-switch-wrapper .e-switch-handle.e-switch-active {margin: 2px 2px 2px -19px}
body .lookupDetail-condition.systemES .content .systemESswitch >>> .e-switch-wrapper .e-ripple-element {background-color: transparent}
body .lookupDetail-condition.systemES.systemESinner {padding: 0;margin: -5px -12px -4px}
body .lookupDetail-condition.systemES.systemESinner .title {width: 88px}
body .lookupDetail-condition.systemES.systemESinner .content {width: calc(100% - 88px)}
</style>

<script>
import commonMixin from '@/views/layout/mixin/commonMixin';
import {
  commonCodesGetCommonCode,
  commonCodesSetStandardInfos,
} from '@/utils/commonCodes';
import {uploadImage} from '@/utils/formUtil';
import ConfigCalenderHolidayPopup from '@/views/code-management/popup/ConfigCalenderHolidayPopup';
import ConfigCardSupplierPopup from '@/views/code-management/popup/ConfigCardSupplierPopup';
import ConfigCourseHoleInfoPopup from '@/views/code-management/popup/ConfigCourseHoleInfoPopup';
import ConfigPaymentLinePopup from '@/views/code-management/popup/ConfigPaymentLinePopup';
import ConfigAccountInfoPopup from '@/views/code-management/popup/ConfigAccountInfoPopup';
import ConfigPenaltyPopup from '@/views/code-management/popup/ConfigPenaltyPopup';
import ConfigWeekendDrwtPopup from '@/views/code-management/popup/ConfigWeekendDrwtPopup';
import ConfigYearGrpPopup from "@/views/code-management/popup/ConfigYearGrpPopup";
import ConfigSmsTextPopup from '@/views/code-management/popup/ConfigSmsTextPopup';
import ConfigSmsEndPersonPopup from '@/views/code-management/popup/ConfigSmsEndPersonPopup';
import ConfigKakaoSendKeyPopup from '@/views/code-management/popup/ConfigKakaoSendKeyPopup';
import ConfigEndCheckListPopup from '@/views/code-management/popup/ConfigEndCheckListPopup';
import ConfigMemberGradeSettingPopup from '@/views/code-management/popup/ConfigMemberGradeSettingPopup';
import RoomCalenderConfPopup from "@/views/code-management/popup/RoomCalenderConfPopup";
import {getSystemConfigInfo, putSystemConfigInfo} from '@/api/systemConfig';
import InputNumber from '@/components/common/InputNumber';
import InputTime from '@/components/common/datetime/InputTime';
import routeViewMixin from '@/views/layout/mixin/routeViewMixin';
import InputText from '@/components/common/text/InputText';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import GolfErpAPI from '@/api/v2/GolfErpAPI';
import ComponentTelephone from "@/components/ComponentTelephone";
import InputTextarea from "@/components/common/text/InputTextarea";
import ConfigTsConfCom2ByMemberDivPopup from "@/views/code-management/popup/ConfigTsConfCom2ByMemberDivPopup.vue";
import ErpButton from "@/components/button/ErpButton";

const COURSE_PUBLIC = 'P';

export default {
  name: 'SystemConfig',
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  components: {
    ComponentTelephone,
    InputText,
    InputTime,
    ConfigYearGrpPopup,
    ConfigCalenderHolidayPopup,
    ConfigCardSupplierPopup,
    ConfigCourseHoleInfoPopup,
    ConfigPaymentLinePopup,
    ConfigAccountInfoPopup,
    ConfigPenaltyPopup,
    ConfigWeekendDrwtPopup,
    ConfigSmsTextPopup,
    ConfigSmsEndPersonPopup,
    ConfigKakaoSendKeyPopup,
    ConfigEndCheckListPopup,
    ConfigMemberGradeSettingPopup,
    InputNumber,
    InputTextarea,
    RoomCalenderConfPopup,
    ConfigTsConfCom2ByMemberDivPopup,
    ErpButton,
  },
  async created() {
    if (this.courseClassCodes) {
      this.systemConfigInfo.courseClass = this.courseClassCodes[0].comCode;
    }
    await this.fetchSystemConfigInfo();
  },
  async beforeDestroy() {
    await Promise.all([
      (async () => {
        if (this.isLogoImageUploadedAndNotSaved) {
          await this.deleteConfFile('LOGO');
        }
      })(),
      (async () => {
        if (this.isCtpnImageUploadedAndNotSaved) {
          await this.deleteConfFile('CTPN');
        }
      })(),
      (async () => {
        if (this.isPrintImageUploadedAndNotSaved) {
          await this.deleteConfFile('PRINT');
        }
      })(),
    ]);
  },
  watch: {
    isSectionNo10Visible() {
      if (this.isSectionNo10Visible) {
        this.onScrollToEnd();
      }
    },
  },
  data() {
    return {
      isSectionNo1Visible: true,
      isSectionNo2Visible: false,
      isSectionNo3Visible: false,
      isSectionNo4Visible: false,
      isSectionNo5Visible: false,
      isSectionNo6Visible: false,
      isSectionNo7Visible: false,
      isSectionNo8Visible: false,
      isSectionNo9Visible: false,
      isSectionNo10Visible: false,
      isSectionNo11Visible: false,
      isSectionNo12Visible: false,
      isSectionNo13Visible: false,
      isSectionNo14Visible: false,
      isSectionNo15Visible: false,
      isRoomCalenderConfPopupOpen: false,
      showConfigCalenderHoliday: false,
      showConfigCardSupplier: false,
      showConfigCourseHoleInfo: false,
      showConfigPaymentLine: false,
      showConfigAccountInfo: false,
      showConfigPenalty: false,
      showConfigYearGrpPopup: false,
      showConfigMemberGradePopup: false,
      showConfigWeekendDrwt: false,
      showConfigSmsText: false,
      showConfigSmsEndPerson: false,
      showConfigKakaoSendKey: false,
      showConfigEndCheckList: false,
      switchRefresh: true,
      isLogoImageUploadedAndNotSaved: false,
      isCtpnImageUploadedAndNotSaved: false,
      isPrintImageUploadedAndNotSaved: false,
      isConfigTsConfCom2ByMemberDivPopup: false,
      roomAmtProductCodes: [],
      courseClassCodes: commonCodesGetCommonCode('COURSE_CLASS'),
      paymtDivisionStdCodes: commonCodesGetCommonCode('PAYMT_DIVIDE_STD'),
      salesPlanDivCodes: commonCodesGetCommonCode("SALES_PLAN_DIV", true),
      cartTeamDivCodes: commonCodesGetCommonCode('CART_TEAM_DIV'),
      cartDateDivCodes: commonCodesGetCommonCode('CART_DATE_DIV'),
      cartHoleDivCodes: commonCodesGetCommonCode('CART_HOLE_DIV'),
      vatCalcMethodCodes: commonCodesGetCommonCode('VAT_CALC_METHOD'),
      paymtCodes: commonCodesGetCommonCode('PAYMT_CODE'),
      pwdChangeCycleCodes: commonCodesGetCommonCode('PWD_CHANGE_CYCLE'),
      pwdScrtyLevelCodes: commonCodesGetCommonCode('PWD_SCRTY_LEVEL'),
      resvePeriodCodes: commonCodesGetCommonCode('RESVE_PERIOD'),
      dwCodes: commonCodesGetCommonCode('DW_CODE'),
      resveOpenMethodCodes: commonCodesGetCommonCode('RESVE_OPEN_METHOD'),
      smsVanCodes: commonCodesGetCommonCode('SMS_VAN'),
      bizNameLocationCodes: commonCodesGetCommonCode('BIZ_NAME_LOCATION'),
      captchaScrtyLevelCodes: commonCodesGetCommonCode('PWD_SCRTY_LEVEL'),
      fields: {text: 'comName', value: 'comCode'},
      systemConfigInfo: {
        courseClass: null,
        roomAmtProductCode: null,
        kioskCmpnExpsrFlag:false,//키오스크 동반자 노출여부
        kioskPerChoiseFlag:false,//키오스크 인원 선택 여부
        kioskUnsetlVisitDelFlag:false, // 키오스크미결제내장객삭제여부
        webMemberEntryDaycnt:0,//홈페이지 재가입 제한 일수
        memberNoAuto: false, //회원번호 자동생성
        resveCmpnAuto: false, //예약 동반자 자동 등록
        resvePwdConfirm: false, //예약시 비밀번호 확인
        startTime: null,
        endTime: null,
        billVisitDsp: false, //계산서 내장객명 표시
        visitLockerAuto: false, //내장객 락카키 자동 생성
        variableLockerUseFlag: false, // 가변 락카 사용 여부
        lockerReturnFlag: false, // 락카 반납 여부
        lockerZoneUseFlag: false, // 락카존선택사용여부
        penltyUse: false, //골프위약관리
        memberGradeUse: false, // 회원등급제사용
        infrPaymt: null, //인프라요금
        paymtDivideUnit: null, //분할단위
        paymtDivideStd: null, //분할기준
        myTax: null, //제세금
        specialTax: null, //특별소비세
        eduTax: null, //교육세
        eduTaxConf: null, //교육세설정
        vilTax: null, //부가세
        vilTaxConf: null, //부가세 설정
        vat: null, //부가세
        vatConf: null, //부가세 설정
        fund1: null, //체육기금
        fundStart1: null, //체육기금 시작
        fundEnd1: null, //체육기금 종료
        fund2: null, //체육기금
        fundStart2: null, //체육기금 시작
        fundEnd2: null, //체육기금 종료
        fund3: null, //체육기금
        fundStart3: null, //체육기금 시작
        fundEnd3: null, //체육기금 종료
        fund4: null, //체육기금
        fundStart4: null, //체육기금 시작
        fundEnd4: null, //체육기금 종료
        fund5: null, //체육기금
        fundStart5: null, //체육기금 시작
        fundEnd5: null, //체육기금 종료
        vatCalcMethod: null, //부가세 계산방식
        cartTeamDiv: null, //카트팀구분
        cartDateDiv: null, //카트일자구분
        cartHoleDiv: null, //카트홀구분
        nomemberPaymtCode: null, //비회원 요금 코드
        loginFailLmtt: false, //로그인 실패 횟수 제한
        loginFailCnt: 1, //로그인 실패 횟수
        autoLogout: false, //자동 로그아웃
        autoLogoutTime: 1, //자동 로그아웃 시간
        drmncyAcntUse: false, //휴면 계정 사용
        drmncyAcntProcess: 1, //휴면 계정 처리
        drmncyAcntDel: 1, //휴면 계정 삭제
        pwdMinLength: 1, //비밀번호 최소 자리
        pwdMaxLength: 1, //비밀번호 최대 자리
        pwdChangeCycle: null, //비밀번호 변경주기
        pwdNextPerm: false, //다음에 변경하기 사용
        pwdScrtyLevel: null, //비밀번호 보안 수준
        weekdayResveTerm: null, //주중 예약 기간
        weekdayResveScore: 0, // 주중 예약 점수
        weekendResveTerm: null, //주말 예약 기간
        weekendResveScore: 0, // 주말 예약 점수
        weekdayWaitingUse: false, //주중 대기예약 사용
        weekendDrwtUse: false, //주말 추첨 사용
        weekendDrwtPenltyCheck: false, //주말 추첨 위약 체크
        weekendRqstde: 1, //주말신청일
        weekendRqstdw: null, //주말신청요일
        weekendRqsttm: null, //주말신청시간
        weekendReqstClosde: 1, //주말신청마감일
        weekendReqstClosdw: null, //주말신청마감요일
        weekendReqstClostm: null, //주말신청마감시간
        weekendConfde: 1, //주말확정일
        weekendConfdw: null, //주말확정요일
        weekendConftm: null, //주말확정시간
        teeoffStartTime: null, //티오프시작시간
        teeoffEndTime: null, //티오프종료시간
        teeOffIntrvl1: 1, //티오프간격1
        teeOffIntrvl2: 1, //티오프간격2
        teeOffIntrvl3: 1, //티오프간격3
        resveOpenMethod: null, //예약오픈방법
        resveOpenDayCnt: 1, //예약오픈일수
        resveOpenTime: null, //예약오픈시간
        resveClosdeCnt: 1, //예약마감일수
        resveClostm: null, //예약마감시간
        weekdayCncde: 1, //주중취소일
        weekdayCnctime: null, //주중취소시간
        weekendCncde: 1, //주말취소일
        weekendCnctime: null, //주말취소시간
        weekdayChgde: 1, //주중변경일
        weekdayChgtime: null, //주중변경시간
        weekendChgde: 1, //주말변경일
        weekendChgtime: null, //주말변경시간
        todayResveFlag: true, //당일예약여부
        todayResveTime: 1, //당일예약시간
        todayResveOpenTime: null, //당일예약오픈시간
        caddieTcketNtcn: true, //배치표 알림메세지 출력
        smsUse: false, //SMS사용
        smsVan: null, //SMS VAN
        dsptchNo: null, //발신번호
        bizName: null, //사업자명
        bizNameLocation: null, //사업장 위치
        resveSmsSendFlag: false, //예약SMS 전송여부
        smsAutoSendDe1: 1, //SMS자동발송일1
        smsAutoSendTime1: null, //SMS자동발송시간1
        smsAutoSendDe2: 1, //SMS자동발송일2
        smsAutoSendTime2: null, //SMS자동발송시간2
        smsAutoSendDe3: 1, //SMS자동발송일3
        smsAutoSendTime3: null, //SMS자동발송시간3
        nearbySmsSendFlag: false, // 지역민 SMS 전송여부
        nearbySmsAutoSendDay1: 1, // 지역민 SMS 자동발송일1
        nearbySmsAutoSendTime1: null, // 지역민 SMS 자동발송시간1
        nearbySmsAutoSendDay2: 1, // 지역민 SMS 자동발송일2
        nearbySmsAutoSendTime2: null, // 지역민 SMS 자동발송시간2
        kakaoNtcnUse: true, //카카오 알림톡 여부
        kakaoDsptchKey: null, // 카카오 발신 KEY
        weatherInfoUseFlag: true, // 기상 정보 사용 여부
        weatherAreaCode: {
          landFcst: null,
          midLandFcst: null,
          midTa: null,
          nx: 0, // 단기예보 X
          ny: 0, // 단기예보 Y
        }, // 기상 지역 코드
        weatherAreaName: null, // 기상 지역명
        weatherInfoUrl: null, // 기상 정보 URL
        imageLogoURL: null, //로고 이미지 path
        imageLogoId: null, //로고 이미지 id
        imageCtpnURL: null, //도장 이미지 path
        imageCtpnId: null, //도장 이미지 id
        imagePrintURL: null, //프린터 로고 path
        imagePrintId: null, //프린터 로고 id,
        penltyDpmnyUseFlag: false,
        penltyDpmnyReSscbl: 0,
        noneChkinUseFlag: false,
        noneChkinName: null,
        noneChkinLa: 0,
        noneChkinLo: 0,
        noneChkinDstnc: 0,
        noneChkinSmsWords: null,
        prpayPayUseFlag: null, // 선불결제 사용 여부
        prpayPayStartTime: null, // 선불결제 시작 시간
        prpayPayEndTime: null, // 선불결제 종료 시간
        noneChkinSmsVisitWords: null, // 무인 체크인 내장객 SMS 문구
        captchaUseFlag: false,
        captchaCipher: 4,
        captchaScrtyLevel: "L",
        memoConfirmFlag: null,
        productVatSpuse:false,    // 상품입고시 부가세 별도 여부
        suppliesVatSpuse:false,   // 저장품입고시 부가세 별도 여부

        // 환경설정 공통2(TS_CONF_COM2)
        homepageLoginConf: null,
        resveRceptConf: null,
        cmpnInsertConf: null,
        visitInsertConf: null,
        depositBlceConf: null,
        autoDiv: null,
        smsSendStdde: null,
        smsSendTime: null,
        confCom2MemberDiv: null,
        salesPlanDiv: null,
      },
      buttons: {
        browse: '등록',
      },
      configImageFileUploaderExtensions: '.png, .jpg, .jpeg, .gif, .bmp',
    };
  },
  computed: {
    isPopupOpened() {
      return (
          this.showConfigCalenderHoliday ||
          this.showConfigCardSupplier ||
          this.showConfigCourseHoleInfo ||
          this.showConfigPaymentLine ||
          this.showConfigAccountInfo ||
          this.showConfigPenalty ||
          this.showConfigMemberGradePopup ||
          this.showConfigWeekendDrwt ||
          this.showConfigSmsText ||
          this.showConfigSmsEndPerson ||
          this.showConfigKakaoSendKey ||
          this.showConfigEndCheckList ||
          this.isRoomCalenderConfPopupOpen
      );
    },
  },
  methods: {
    preventEnterEvent(event) {
      if (event.key === 'Enter') {
        event.preventDefault();
      }
    },
    isPublicCourse() {
      return this.systemConfigInfo.courseClass === COURSE_PUBLIC;
    },
    async fetchSystemConfigInfo() {
      let {
        value: {
          confCom,
          roomAmtProductCodes,
        },
      } = await getSystemConfigInfo();
      if (confCom.weatherAreaCode) {
        confCom.weatherAreaCode = JSON.parse(confCom.weatherAreaCode);
      }
      if (!confCom.autoDiv) {
        confCom.autoDiv = "AUTO";
      }
      this.roomAmtProductCodes = roomAmtProductCodes.map(item => {
        return {
          comCode: item.code,
          comName: item.name,
        };
      });
      this.systemConfigInfo = confCom;
    },
    async onConfigLogoFileSelect(args) {
      const file = args.event.target.files[0];

      const uploadResponse = await uploadImage(
          this.systemConfigInfo.imageLogoId,
          'CONF',
          args.filesData,
          file,
      );
      if (uploadResponse) {
        this.systemConfigInfo.imageLogoId = uploadResponse.imageId;
        this.systemConfigInfo.imageLogoURL = uploadResponse.imageURL;
        this.isLogoImageUploadedAndNotSaved = true;
      }

      this.$refs.logoFileUploader.clearAll(); // 다시 upload 하지 않도록 clear
    },
    async onConfigStampFileSelect(args) {
      const file = args.event.target.files[0];

      const uploadResponse = await uploadImage(
          this.systemConfigInfo.imageCtpnId,
          'CONF',
          args.filesData,
          file,
      );
      if (uploadResponse) {
        this.systemConfigInfo.imageCtpnId = uploadResponse.imageId;
        this.systemConfigInfo.imageCtpnURL = uploadResponse.imageURL;
        this.isCtpnImageUploadedAndNotSaved = true;
      }

      this.$refs.stampFileUploader.clearAll(); // 다시 upload 하지 않도록 clear
    },
    async onConfigPrintLogoFileSelect(args) {
      const file = args.event.target.files[0];
      const uploadResponse = await uploadImage(
          this.systemConfigInfo.imagePrintId,
          'CONF',
          args.filesData,
          file,
      );
      if (uploadResponse) {
        this.systemConfigInfo.imagePrintId = uploadResponse.imageId;
        this.systemConfigInfo.imagePrintURL = uploadResponse.imageURL;
        this.isPrintImageUploadedAndNotSaved = true;
      }

      this.$refs.printLogoFileUploader.clearAll(); // 다시 upload 하지 않도록 clear
    },
    async onDeleteConfigLogoImage() {
      if (await this.confirm('사진을 삭제하시겠습니까?')) {
        this.$refs.logoFileUploader.clearAll();
        if (
            this.systemConfigInfo.imageLogoId &&
            this.systemConfigInfo.imageLogoURL
        ) {
          await this.deleteConfFile('LOGO');
        }
      }
    },
    async onDeleteConfigStampImage() {
      if (await this.confirm('사진을 삭제하시겠습니까?')) {
        this.$refs.stampFileUploader.clearAll();
        if (
            this.systemConfigInfo.imageCtpnId &&
            this.systemConfigInfo.imageCtpnURL
        ) {
          await this.deleteConfFile('CTPN');
        }
      }
    },
    async onDeleteConfigPrintLogoImage() {
      if (await this.confirm('사진을 삭제하시겠습니까?')) {
        this.$refs.printLogoFileUploader.clearAll();
        if (
            this.systemConfigInfo.imagePrintId &&
            this.systemConfigInfo.imagePrintId
        ) {
          await this.deleteConfFile('PRINT');
        }
      }
    },
    async onInitClicked() {
      await this.fetchSystemConfigInfo();
    },
    async onSaveClicked() {
      let confCom = this.systemConfigInfo;

      if (confCom.weatherAreaCode) {
        confCom.weatherAreaCode = JSON.stringify(confCom.weatherAreaCode);
      }

      const {data} = await putSystemConfigInfo(confCom);
      const {standardInfos} = data;

      // 시스템 환경설정 저장
      commonCodesSetStandardInfos(standardInfos);

      this.isLogoImageUploadedAndNotSaved = this
          .isLogoImageUploadedAndNotSaved
          ? false
          : false;
      this.isCtpnImageUploadedAndNotSaved = this
          .isCtpnImageUploadedAndNotSaved
          ? false
          : false;
      this.isPrintImageUploadedAndNotSaved = this
          .isPrintImageUploadedAndNotSaved
          ? false
          : false;

      await this.fetchSystemConfigInfo();

      this.infoToast(this.$t('main.popupMessage.saved'));
    },
    onConfigCalenderHolidayPopupClose() {
      this.showConfigCalenderHoliday = false;
    },
    onShowConfigCalenderPopup() {
      this.showConfigCalenderHoliday = true;
      this.$nextTick(() => {
        this.$refs.configCalenderHolidayPopup.onShowConfigCalenderHolidayPopup();
      });
    },
    onShowRoomCalenderConfPopup() {
      this.isRoomCalenderConfPopupOpen = true;

      this.$nextTick(() => {
        this.$refs.roomCalenderConfPopup.showPopup();
      });
    },
    onConfigCardSupplierPopupClose() {
      this.showConfigCardSupplier = false;
    },
    onShowConfigCardSupplierPopup() {
      this.showConfigCardSupplier = true;
      this.$nextTick(() => {
        this.$refs.configCardSupplierPopup.onShowConfigCardSupplierPopup();
      });
    },
    onConfigCourseHoleInfoPopupClose() {
      this.showConfigCourseHoleInfo = false;
    },
    onShowConfigCourseHoleInfoPopup() {
      this.showConfigCourseHoleInfo = true;
      this.$nextTick(() => {
        this.$refs.configCourseHoleInfoPopup.onShowConfigCourseHoleInfoPopup();
      });
    },
    onConfigPaymentLinePopupClose() {
      this.showConfigPaymentLine = false;
    },
    onShowConfigPaymentLinePopup() {
      this.showConfigPaymentLine = true;
      this.$nextTick(() => {
        this.$refs.configPaymentLinePopup.onShowConfigPaymentLinePopup();
      });
    },
    onConfigAccountInfoPopupClose() {
      this.showConfigAccountInfo = false;
    },
    onShowConfigAccountInfoPopup() {
      this.showConfigAccountInfo = true;
      this.$nextTick(() => {
        this.$refs.configAccountInfoPopup.onShowConfigAccountInfoPopup();
      });
    },
    async deleteConfFile(type) {
      let imageId = null;
      if (type === 'LOGO') {
        imageId = JSON.stringify(this.systemConfigInfo.imageLogoId);
        this.systemConfigInfo.imageLogoURL = null;
        this.systemConfigInfo.imageLogoId = null;
        this.isLogoImageUploadedAndNotSaved = false;
      } else if (type === 'CTPN') {
        imageId = JSON.stringify(this.systemConfigInfo.imageCtpnId);
        this.systemConfigInfo.imageCtpnURL = null;
        this.systemConfigInfo.imageCtpnId = null;
        this.isCtpnImageUploadedAndNotSaved = false;
      } else if (type === 'PRINT') {
        imageId = JSON.stringify(this.systemConfigInfo.imagePrintId);
        this.systemConfigInfo.imagePrintId = null;
        this.systemConfigInfo.imagePrintURL = null;
        this.isPrintImageUploadedAndNotSaved = false;
      }
      await GolfErpAPI.deleteImage(imageId);
    },
    onShowConfigYearGrpPopup() {
      this.showConfigYearGrpPopup = true;
    },
    onConfigYearGrpPopupClose() {
      this.showConfigYearGrpPopup = false;
    },
    async onConfigTsConfCom2ByMemberDivPopupShow(idx) {
      if (idx === 9 && !this.systemConfigInfo.drmncyAcntUse) {
        return;
      }
      this.isConfigTsConfCom2ByMemberDivPopup = true;

      await this.$nextTick(() => {
        this.$refs.configTsConfCom2ByMemberDivPopup.showPopup(idx);
      });
    },
    onConfigTsConfCom2ByMemberDivPopupClose(isSaved, confCom2MemberDiv) {
      this.isConfigTsConfCom2ByMemberDivPopup = false;

      if (isSaved) {
        this.systemConfigInfo.confCom2MemberDiv = confCom2MemberDiv;
      }
    },
    onShowConfigPenaltyPopup() {
      this.showConfigPenalty = true;
      this.$nextTick(() => {
        this.$refs.configPenaltyPopup.onShowConfigPenaltyPopup();
      });
    },
    onConfigPenaltyPopupClose() {
      this.showConfigPenalty = false;
    },
    onPenaltyUseChange(args) {
      if (args.checked) {
        this.onShowConfigPenaltyPopup();
      }
    },
    onMemberGradeUseChange(args) {
      if (args.checked) {
        this.onShowConfigMemberGradePopup();
      }
    },
    onShowConfigMemberGradePopup() {
      this.showConfigMemberGradePopup = true;
      this.$nextTick(() => {
        this.$refs.configMemberGradeSettingPopup.showPopup();
      });
    },
    onConfigMemberGradePopupClose() {
      this.showConfigMemberGradePopup = false;
    },
    onRoomCalenderConfPopupOpenClose() {
      this.isRoomCalenderConfPopupOpen = false;
    },
    onShowConfigWeekendDrwtPopup() {
      if (!this.systemConfigInfo.weekendDrwtUse) {
        return;
      }
      this.showConfigWeekendDrwt = true;
      this.$nextTick(() => {
        this.$refs.configWeekendDrwtPopup.onShowConfigWeekendDrwtPopup();
      });
    },
    onConfigWeekendDrwtPopupClose() {
      this.showConfigWeekendDrwt = false;
    },
    onShowConfigSmsTextPopup() {
      this.showConfigSmsText = true;
      this.$nextTick(() => {
        this.$refs.configSmsTextPopup.onShowConfigSmsTextPopup();
      });
    },
    onConfigSmsTextPopupClose() {
      this.showConfigSmsText = false;
    },
    onShowConfigSmsEndPersonPopup() {
      this.showConfigSmsEndPerson = true;
      this.$nextTick(() => {
        this.$refs.configSmsEndPersonPopup.onShowConfigSmsEndPersonPopup();
      });
    },
    onConfigSmsEndPersonPopupClose() {
      this.showConfigSmsEndPerson = false;
    },
    onShowConfigKakaoSendKeyPopup() {
      this.showConfigKakaoSendKey = true;
      this.$nextTick(() => {
        this.$refs.configKakaoSendKeyPopup.onShowConfigKakaoSendKeyPopup();
      });
    },
    onConfigKakaoSendKeyPopupClose() {
      this.showConfigKakaoSendKey = false;
    },
    onShowConfigEndCheckListPopup() {
      this.showConfigEndCheckList = true;
      this.$nextTick(() => {
        this.$refs.configEndCheckListPopup.onShowConfigEndCheckListPopup();
      });
    },
    onConfigEndCheckListPopupClose() {
      this.showConfigEndCheckList = false;
    },
    onScrollToEnd() {
      const container = this.$el.querySelector('.section-body');
      this.$nextTick(() => {
        container.scrollTop = container.scrollHeight;
      });
    },
  },
};
</script>
